import { ChevronLeft } from "lucide-react";
import { useRouter } from "next/router";

const BackButton = () => {
  const router = useRouter();
  return (
    <div className="x-padding flex w-full">
      <ChevronLeft />
      <button onClick={() => router.back()}>Back</button>
    </div>
  );
};

export default BackButton;
