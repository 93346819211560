import { useRouter } from "next/router";

const cantGoBackRoutes = ["/", "/404"];

export const useCanGoBack = () => {
  const router = useRouter();
  return !cantGoBackRoutes.includes(router.pathname);
};

export const useHeaderPadding = () => {
  const canGoBack = useCanGoBack();
  return canGoBack ? "pt-[126px] md:pt-[150px]" : "pt-[96px] md:pt-[120px]";
};
