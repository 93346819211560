// Generated by @wagmi/cli@0.1.11 on 2023-03-13 at 12:01:05 p.m.
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useContract,
  type UseContractConfig,
  useContractRead,
  type UseContractReadConfig,
  useContractWrite,
  type UseContractWriteConfig,
  usePrepareContractWrite,
  type UsePrepareContractWriteConfig,
  useContractEvent,
  type UseContractEventConfig,
} from "wagmi";
import {
  type ReadContractResult,
  type WriteContractMode,
  type PrepareWriteContractResult,
} from "wagmi/actions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ConventionalBNBPoolV1
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const conventionalBnbPoolV1ABI = [
  {
    stateMutability: "nonpayable",
    type: "constructor",
    inputs: [
      { name: "_owner", internalType: "address payable", type: "address" },
      {
        name: "_distributor",
        internalType: "address payable",
        type: "address",
      },
    ],
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "Staker",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "CLAIM",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "previousOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "newOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "OwnershipTransferred",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "staker",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "RESTAKE",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "Staker",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "STAKE",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "Staker",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "WITHDRAW",
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      {
        name: "_distributor",
        internalType: "address payable",
        type: "address",
      },
    ],
    name: "ChangeDistributor",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_token", internalType: "uint256", type: "uint256" },
      { name: "_lp", internalType: "uint256", type: "uint256" },
    ],
    name: "SetMinAmount",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_token", internalType: "uint256", type: "uint256" },
      { name: "_lp", internalType: "uint256", type: "uint256" },
      { name: "_divider", internalType: "uint256", type: "uint256" },
    ],
    name: "SetPoolsReward",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_tax", internalType: "uint256", type: "uint256" },
      { name: "_duration", internalType: "uint256", type: "uint256" },
    ],
    name: "SetWithdrawTaxAndDuration",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_user", internalType: "address", type: "address" }],
    name: "calculateLpReward",
    outputs: [{ name: "_reward", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_user", internalType: "address", type: "address" }],
    name: "calculateTokenReward",
    outputs: [{ name: "_reward", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_index", internalType: "uint256", type: "uint256" }],
    name: "claim",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "distributor",
    outputs: [{ name: "", internalType: "address payable", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_lpAmount", internalType: "uint256", type: "uint256" }],
    name: "getTokenForLP",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "getTokenReserve",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_user", internalType: "address", type: "address" }],
    name: "getUserInfo",
    outputs: [
      { name: "_isExists", internalType: "bool", type: "bool" },
      { name: "_stakeCount", internalType: "uint256", type: "uint256" },
      { name: "_totalStakedToken", internalType: "uint256", type: "uint256" },
      { name: "_totalStakedLp", internalType: "uint256", type: "uint256" },
      {
        name: "_totalWithdrawanToken",
        internalType: "uint256",
        type: "uint256",
      },
      { name: "_totalWithdrawanLp", internalType: "uint256", type: "uint256" },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "liquidityPair",
    outputs: [{ name: "", internalType: "contract IPair", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "lpReward",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "minLp",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "minToken",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "owner",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_index", internalType: "uint256", type: "uint256" }],
    name: "reStake",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "rewardDivider",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_amount", internalType: "uint256", type: "uint256" },
      { name: "_index", internalType: "uint256", type: "uint256" },
    ],
    name: "stake",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "token",
    outputs: [{ name: "", internalType: "contract IERC20", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "tokenReward",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalLpStakers",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalStakedLp",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalStakedToken",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalTokenStakers",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalWithdrawanLp",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalWithdrawanToken",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "newOwner", internalType: "address", type: "address" }],
    name: "transferOwnership",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "uniqueStakers",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_user", internalType: "address", type: "address" }],
    name: "userLpStakeInfo",
    outputs: [
      { name: "_lpAmount", internalType: "uint256", type: "uint256" },
      { name: "_amount", internalType: "uint256", type: "uint256" },
      { name: "_time", internalType: "uint256", type: "uint256" },
      { name: "_reward", internalType: "uint256", type: "uint256" },
      { name: "_startTime", internalType: "uint256", type: "uint256" },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_user", internalType: "address", type: "address" }],
    name: "userTokenStakeInfo",
    outputs: [
      { name: "_amount", internalType: "uint256", type: "uint256" },
      { name: "_time", internalType: "uint256", type: "uint256" },
      { name: "_reward", internalType: "uint256", type: "uint256" },
      { name: "_startTime", internalType: "uint256", type: "uint256" },
    ],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_index", internalType: "uint256", type: "uint256" }],
    name: "withdraw",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "withdrawDuration",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "withdrawTaxPercent",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
] as const;

export const conventionalBnbPoolV1Address =
  "0x8FD50c8886dc91111E52BBFcb2685368c29Bdc8d" as const;

export const conventionalBnbPoolV1Config = {
  address: conventionalBnbPoolV1Address,
  abi: conventionalBnbPoolV1ABI,
} as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__.
 */
export function useConventionalBnbPoolV1(
  config: Omit<UseContractConfig, "abi" | "address"> = {} as any
) {
  return useContract({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    ...config,
  });
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__.
 */
export function useConventionalBnbPoolV1Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    TFunctionName
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      TFunctionName,
      TSelectData
    >,
    "abi" | "address"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"calculateLpReward"`.
 */
export function useConventionalBnbPoolV1CalculateLpReward<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "calculateLpReward"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "calculateLpReward",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "calculateLpReward",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "calculateLpReward", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"calculateTokenReward"`.
 */
export function useConventionalBnbPoolV1CalculateTokenReward<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "calculateTokenReward"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "calculateTokenReward",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "calculateTokenReward",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "calculateTokenReward", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"distributor"`.
 */
export function useConventionalBnbPoolV1Distributor<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "distributor"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "distributor",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "distributor",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "distributor", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"getTokenForLP"`.
 */
export function useConventionalBnbPoolV1GetTokenForLp<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "getTokenForLP"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "getTokenForLP",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "getTokenForLP",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "getTokenForLP", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"getTokenReserve"`.
 */
export function useConventionalBnbPoolV1GetTokenReserve<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "getTokenReserve"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "getTokenReserve",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "getTokenReserve",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "getTokenReserve", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"getUserInfo"`.
 */
export function useConventionalBnbPoolV1GetUserInfo<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "getUserInfo"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "getUserInfo",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "getUserInfo",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "getUserInfo", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"liquidityPair"`.
 */
export function useConventionalBnbPoolV1LiquidityPair<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "liquidityPair"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "liquidityPair",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "liquidityPair",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "liquidityPair", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"lpReward"`.
 */
export function useConventionalBnbPoolV1LpReward<
  TSelectData = ReadContractResult<typeof conventionalBnbPoolV1ABI, "lpReward">
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "lpReward",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "lpReward",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "lpReward", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"minLp"`.
 */
export function useConventionalBnbPoolV1MinLp<
  TSelectData = ReadContractResult<typeof conventionalBnbPoolV1ABI, "minLp">
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "minLp",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "minLp",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "minLp", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"minToken"`.
 */
export function useConventionalBnbPoolV1MinToken<
  TSelectData = ReadContractResult<typeof conventionalBnbPoolV1ABI, "minToken">
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "minToken",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "minToken",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "minToken", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"owner"`.
 */
export function useConventionalBnbPoolV1Owner<
  TSelectData = ReadContractResult<typeof conventionalBnbPoolV1ABI, "owner">
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "owner",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "owner",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "owner", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"rewardDivider"`.
 */
export function useConventionalBnbPoolV1RewardDivider<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "rewardDivider"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "rewardDivider",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "rewardDivider",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "rewardDivider", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"token"`.
 */
export function useConventionalBnbPoolV1Token<
  TSelectData = ReadContractResult<typeof conventionalBnbPoolV1ABI, "token">
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "token",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "token",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "token", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"tokenReward"`.
 */
export function useConventionalBnbPoolV1TokenReward<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "tokenReward"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "tokenReward",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "tokenReward",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "tokenReward", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"totalLpStakers"`.
 */
export function useConventionalBnbPoolV1TotalLpStakers<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "totalLpStakers"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "totalLpStakers",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "totalLpStakers",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "totalLpStakers", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"totalStakedLp"`.
 */
export function useConventionalBnbPoolV1TotalStakedLp<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "totalStakedLp"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "totalStakedLp",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "totalStakedLp",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "totalStakedLp", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"totalStakedToken"`.
 */
export function useConventionalBnbPoolV1TotalStakedToken<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "totalStakedToken"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "totalStakedToken",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "totalStakedToken",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "totalStakedToken", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"totalTokenStakers"`.
 */
export function useConventionalBnbPoolV1TotalTokenStakers<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "totalTokenStakers"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "totalTokenStakers",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "totalTokenStakers",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "totalTokenStakers", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"totalWithdrawanLp"`.
 */
export function useConventionalBnbPoolV1TotalWithdrawanLp<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "totalWithdrawanLp"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "totalWithdrawanLp",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "totalWithdrawanLp",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "totalWithdrawanLp", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"totalWithdrawanToken"`.
 */
export function useConventionalBnbPoolV1TotalWithdrawanToken<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "totalWithdrawanToken"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "totalWithdrawanToken",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "totalWithdrawanToken",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "totalWithdrawanToken", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"uniqueStakers"`.
 */
export function useConventionalBnbPoolV1UniqueStakers<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "uniqueStakers"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "uniqueStakers",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "uniqueStakers",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "uniqueStakers", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"userLpStakeInfo"`.
 */
export function useConventionalBnbPoolV1UserLpStakeInfo<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "userLpStakeInfo"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "userLpStakeInfo",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "userLpStakeInfo",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "userLpStakeInfo", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"userTokenStakeInfo"`.
 */
export function useConventionalBnbPoolV1UserTokenStakeInfo<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "userTokenStakeInfo"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "userTokenStakeInfo",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "userTokenStakeInfo",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "userTokenStakeInfo", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"withdrawDuration"`.
 */
export function useConventionalBnbPoolV1WithdrawDuration<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "withdrawDuration"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "withdrawDuration",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "withdrawDuration",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "withdrawDuration", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"withdrawTaxPercent"`.
 */
export function useConventionalBnbPoolV1WithdrawTaxPercent<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV1ABI,
    "withdrawTaxPercent"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV1ABI,
      "withdrawTaxPercent",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "withdrawTaxPercent",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV1ABI, "withdrawTaxPercent", TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__.
 */
export function useConventionalBnbPoolV1Write<
  TMode extends WriteContractMode,
  TFunctionName extends string
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV1ABI,
          string
        >["abi"],
        TFunctionName
      >
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV1ABI,
        TFunctionName
      > & {
        abi?: never;
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV1ABI,
    TFunctionName
  >({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"ChangeDistributor"`.
 */
export function useConventionalBnbPoolV1ChangeDistributor<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV1ABI,
          "ChangeDistributor"
        >["abi"],
        "ChangeDistributor"
      > & { functionName?: "ChangeDistributor" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV1ABI,
        "ChangeDistributor"
      > & {
        abi?: never;
        functionName?: "ChangeDistributor";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV1ABI,
    "ChangeDistributor"
  >({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "ChangeDistributor",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"SetMinAmount"`.
 */
export function useConventionalBnbPoolV1SetMinAmount<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV1ABI,
          "SetMinAmount"
        >["abi"],
        "SetMinAmount"
      > & { functionName?: "SetMinAmount" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV1ABI,
        "SetMinAmount"
      > & {
        abi?: never;
        functionName?: "SetMinAmount";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV1ABI,
    "SetMinAmount"
  >({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "SetMinAmount",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"SetPoolsReward"`.
 */
export function useConventionalBnbPoolV1SetPoolsReward<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV1ABI,
          "SetPoolsReward"
        >["abi"],
        "SetPoolsReward"
      > & { functionName?: "SetPoolsReward" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV1ABI,
        "SetPoolsReward"
      > & {
        abi?: never;
        functionName?: "SetPoolsReward";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV1ABI,
    "SetPoolsReward"
  >({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "SetPoolsReward",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"SetWithdrawTaxAndDuration"`.
 */
export function useConventionalBnbPoolV1SetWithdrawTaxAndDuration<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV1ABI,
          "SetWithdrawTaxAndDuration"
        >["abi"],
        "SetWithdrawTaxAndDuration"
      > & { functionName?: "SetWithdrawTaxAndDuration" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV1ABI,
        "SetWithdrawTaxAndDuration"
      > & {
        abi?: never;
        functionName?: "SetWithdrawTaxAndDuration";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV1ABI,
    "SetWithdrawTaxAndDuration"
  >({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "SetWithdrawTaxAndDuration",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"claim"`.
 */
export function useConventionalBnbPoolV1Claim<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV1ABI,
          "claim"
        >["abi"],
        "claim"
      > & { functionName?: "claim" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV1ABI,
        "claim"
      > & {
        abi?: never;
        functionName?: "claim";
      } = {} as any
) {
  return useContractWrite<TMode, typeof conventionalBnbPoolV1ABI, "claim">({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "claim",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"reStake"`.
 */
export function useConventionalBnbPoolV1ReStake<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV1ABI,
          "reStake"
        >["abi"],
        "reStake"
      > & { functionName?: "reStake" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV1ABI,
        "reStake"
      > & {
        abi?: never;
        functionName?: "reStake";
      } = {} as any
) {
  return useContractWrite<TMode, typeof conventionalBnbPoolV1ABI, "reStake">({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "reStake",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useConventionalBnbPoolV1RenounceOwnership<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV1ABI,
          "renounceOwnership"
        >["abi"],
        "renounceOwnership"
      > & { functionName?: "renounceOwnership" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV1ABI,
        "renounceOwnership"
      > & {
        abi?: never;
        functionName?: "renounceOwnership";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV1ABI,
    "renounceOwnership"
  >({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "renounceOwnership",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"stake"`.
 */
export function useConventionalBnbPoolV1Stake<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV1ABI,
          "stake"
        >["abi"],
        "stake"
      > & { functionName?: "stake" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV1ABI,
        "stake"
      > & {
        abi?: never;
        functionName?: "stake";
      } = {} as any
) {
  return useContractWrite<TMode, typeof conventionalBnbPoolV1ABI, "stake">({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "stake",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useConventionalBnbPoolV1TransferOwnership<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV1ABI,
          "transferOwnership"
        >["abi"],
        "transferOwnership"
      > & { functionName?: "transferOwnership" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV1ABI,
        "transferOwnership"
      > & {
        abi?: never;
        functionName?: "transferOwnership";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV1ABI,
    "transferOwnership"
  >({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "transferOwnership",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"withdraw"`.
 */
export function useConventionalBnbPoolV1Withdraw<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV1ABI,
          "withdraw"
        >["abi"],
        "withdraw"
      > & { functionName?: "withdraw" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV1ABI,
        "withdraw"
      > & {
        abi?: never;
        functionName?: "withdraw";
      } = {} as any
) {
  return useContractWrite<TMode, typeof conventionalBnbPoolV1ABI, "withdraw">({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "withdraw",
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__.
 */
export function usePrepareConventionalBnbPoolV1Write<
  TFunctionName extends string
>(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV1ABI,
      TFunctionName
    >,
    "abi" | "address"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV1ABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"ChangeDistributor"`.
 */
export function usePrepareConventionalBnbPoolV1ChangeDistributor(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV1ABI,
      "ChangeDistributor"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "ChangeDistributor",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV1ABI, "ChangeDistributor">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"SetMinAmount"`.
 */
export function usePrepareConventionalBnbPoolV1SetMinAmount(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV1ABI,
      "SetMinAmount"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "SetMinAmount",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV1ABI, "SetMinAmount">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"SetPoolsReward"`.
 */
export function usePrepareConventionalBnbPoolV1SetPoolsReward(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV1ABI,
      "SetPoolsReward"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "SetPoolsReward",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV1ABI, "SetPoolsReward">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"SetWithdrawTaxAndDuration"`.
 */
export function usePrepareConventionalBnbPoolV1SetWithdrawTaxAndDuration(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV1ABI,
      "SetWithdrawTaxAndDuration"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "SetWithdrawTaxAndDuration",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV1ABI, "SetWithdrawTaxAndDuration">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"claim"`.
 */
export function usePrepareConventionalBnbPoolV1Claim(
  config: Omit<
    UsePrepareContractWriteConfig<typeof conventionalBnbPoolV1ABI, "claim">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "claim",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV1ABI, "claim">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"reStake"`.
 */
export function usePrepareConventionalBnbPoolV1ReStake(
  config: Omit<
    UsePrepareContractWriteConfig<typeof conventionalBnbPoolV1ABI, "reStake">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "reStake",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV1ABI, "reStake">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareConventionalBnbPoolV1RenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV1ABI,
      "renounceOwnership"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "renounceOwnership",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV1ABI, "renounceOwnership">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"stake"`.
 */
export function usePrepareConventionalBnbPoolV1Stake(
  config: Omit<
    UsePrepareContractWriteConfig<typeof conventionalBnbPoolV1ABI, "stake">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "stake",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV1ABI, "stake">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareConventionalBnbPoolV1TransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV1ABI,
      "transferOwnership"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "transferOwnership",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV1ABI, "transferOwnership">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `functionName` set to `"withdraw"`.
 */
export function usePrepareConventionalBnbPoolV1Withdraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof conventionalBnbPoolV1ABI, "withdraw">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    functionName: "withdraw",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV1ABI, "withdraw">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__.
 */
export function useConventionalBnbPoolV1Event<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof conventionalBnbPoolV1ABI, TEventName>,
    "abi" | "address"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    ...config,
  } as UseContractEventConfig<typeof conventionalBnbPoolV1ABI, TEventName>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `eventName` set to `"CLAIM"`.
 */
export function useConventionalBnbPoolV1ClaimEvent(
  config: Omit<
    UseContractEventConfig<typeof conventionalBnbPoolV1ABI, "CLAIM">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    eventName: "CLAIM",
    ...config,
  } as UseContractEventConfig<typeof conventionalBnbPoolV1ABI, "CLAIM">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `eventName` set to `"OwnershipTransferred"`.
 */
export function useConventionalBnbPoolV1OwnershipTransferredEvent(
  config: Omit<
    UseContractEventConfig<
      typeof conventionalBnbPoolV1ABI,
      "OwnershipTransferred"
    >,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    eventName: "OwnershipTransferred",
    ...config,
  } as UseContractEventConfig<typeof conventionalBnbPoolV1ABI, "OwnershipTransferred">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `eventName` set to `"RESTAKE"`.
 */
export function useConventionalBnbPoolV1RestakeEvent(
  config: Omit<
    UseContractEventConfig<typeof conventionalBnbPoolV1ABI, "RESTAKE">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    eventName: "RESTAKE",
    ...config,
  } as UseContractEventConfig<typeof conventionalBnbPoolV1ABI, "RESTAKE">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `eventName` set to `"STAKE"`.
 */
export function useConventionalBnbPoolV1StakeEvent(
  config: Omit<
    UseContractEventConfig<typeof conventionalBnbPoolV1ABI, "STAKE">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    eventName: "STAKE",
    ...config,
  } as UseContractEventConfig<typeof conventionalBnbPoolV1ABI, "STAKE">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalBnbPoolV1ABI}__ and `eventName` set to `"WITHDRAW"`.
 */
export function useConventionalBnbPoolV1WithdrawEvent(
  config: Omit<
    UseContractEventConfig<typeof conventionalBnbPoolV1ABI, "WITHDRAW">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalBnbPoolV1ABI,
    address: conventionalBnbPoolV1Address,
    eventName: "WITHDRAW",
    ...config,
  } as UseContractEventConfig<typeof conventionalBnbPoolV1ABI, "WITHDRAW">);
}
