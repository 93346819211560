// Generated by @wagmi/cli@0.1.11 on 2023-03-13 at 12:01:03 p.m.
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useContract,
  type UseContractConfig,
  useContractRead,
  type UseContractReadConfig,
  useContractWrite,
  type UseContractWriteConfig,
  usePrepareContractWrite,
  type UsePrepareContractWriteConfig,
  useContractEvent,
  type UseContractEventConfig,
} from "wagmi";
import {
  type ReadContractResult,
  type WriteContractMode,
  type PrepareWriteContractResult,
} from "wagmi/actions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// QUINT
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const quintABI = [
  {
    type: "event",
    inputs: [
      { name: "owner", type: "address", indexed: true },
      { name: "spender", type: "address", indexed: true },
      { name: "value", type: "uint256", indexed: false },
    ],
    name: "Approval",
  },
  {
    type: "event",
    inputs: [
      { name: "from", type: "address", indexed: true },
      { name: "to", type: "address", indexed: true },
      { name: "value", type: "uint256", indexed: false },
    ],
    name: "Transfer",
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "owner", type: "address" },
      { name: "spender", type: "address" },
    ],
    name: "allowance",
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "spender", type: "address" },
      { name: "amount", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ name: "", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", type: "uint8" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "name",
    outputs: [{ name: "", type: "string" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "symbol",
    outputs: [{ name: "", type: "string" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalSupply",
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "recipient", type: "address" },
      { name: "amount", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ name: "", type: "bool" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "sender", type: "address" },
      { name: "recipient", type: "address" },
      { name: "amount", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ name: "", type: "bool" }],
  },
] as const;

export const quintAddress =
  "0x64619f611248256F7F4b72fE83872F89d5d60d64" as const;

export const quintConfig = { address: quintAddress, abi: quintABI } as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link quintABI}__.
 */
export function useQuint(
  config: Omit<UseContractConfig, "abi" | "address"> = {} as any
) {
  return useContract({ abi: quintABI, address: quintAddress, ...config });
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link quintABI}__.
 */
export function useQuintRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof quintABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof quintABI, TFunctionName, TSelectData>,
    "abi" | "address"
  > = {} as any
) {
  return useContractRead({
    abi: quintABI,
    address: quintAddress,
    ...config,
  } as UseContractReadConfig<typeof quintABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link quintABI}__ and `functionName` set to `"allowance"`.
 */
export function useQuintAllowance<
  TSelectData = ReadContractResult<typeof quintABI, "allowance">
>(
  config: Omit<
    UseContractReadConfig<typeof quintABI, "allowance", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: quintABI,
    address: quintAddress,
    functionName: "allowance",
    ...config,
  } as UseContractReadConfig<typeof quintABI, "allowance", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link quintABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useQuintBalanceOf<
  TSelectData = ReadContractResult<typeof quintABI, "balanceOf">
>(
  config: Omit<
    UseContractReadConfig<typeof quintABI, "balanceOf", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: quintABI,
    address: quintAddress,
    functionName: "balanceOf",
    ...config,
  } as UseContractReadConfig<typeof quintABI, "balanceOf", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link quintABI}__ and `functionName` set to `"decimals"`.
 */
export function useQuintDecimals<
  TSelectData = ReadContractResult<typeof quintABI, "decimals">
>(
  config: Omit<
    UseContractReadConfig<typeof quintABI, "decimals", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: quintABI,
    address: quintAddress,
    functionName: "decimals",
    ...config,
  } as UseContractReadConfig<typeof quintABI, "decimals", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link quintABI}__ and `functionName` set to `"name"`.
 */
export function useQuintName<
  TSelectData = ReadContractResult<typeof quintABI, "name">
>(
  config: Omit<
    UseContractReadConfig<typeof quintABI, "name", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: quintABI,
    address: quintAddress,
    functionName: "name",
    ...config,
  } as UseContractReadConfig<typeof quintABI, "name", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link quintABI}__ and `functionName` set to `"symbol"`.
 */
export function useQuintSymbol<
  TSelectData = ReadContractResult<typeof quintABI, "symbol">
>(
  config: Omit<
    UseContractReadConfig<typeof quintABI, "symbol", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: quintABI,
    address: quintAddress,
    functionName: "symbol",
    ...config,
  } as UseContractReadConfig<typeof quintABI, "symbol", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link quintABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useQuintTotalSupply<
  TSelectData = ReadContractResult<typeof quintABI, "totalSupply">
>(
  config: Omit<
    UseContractReadConfig<typeof quintABI, "totalSupply", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: quintABI,
    address: quintAddress,
    functionName: "totalSupply",
    ...config,
  } as UseContractReadConfig<typeof quintABI, "totalSupply", TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link quintABI}__.
 */
export function useQuintWrite<
  TMode extends WriteContractMode,
  TFunctionName extends string
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof quintABI, string>["abi"],
        TFunctionName
      >
    : UseContractWriteConfig<TMode, typeof quintABI, TFunctionName> & {
        abi?: never;
      } = {} as any
) {
  return useContractWrite<TMode, typeof quintABI, TFunctionName>({
    abi: quintABI,
    address: quintAddress,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link quintABI}__ and `functionName` set to `"approve"`.
 */
export function useQuintApprove<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof quintABI, "approve">["abi"],
        "approve"
      > & { functionName?: "approve" }
    : UseContractWriteConfig<TMode, typeof quintABI, "approve"> & {
        abi?: never;
        functionName?: "approve";
      } = {} as any
) {
  return useContractWrite<TMode, typeof quintABI, "approve">({
    abi: quintABI,
    address: quintAddress,
    functionName: "approve",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link quintABI}__ and `functionName` set to `"transfer"`.
 */
export function useQuintTransfer<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof quintABI, "transfer">["abi"],
        "transfer"
      > & { functionName?: "transfer" }
    : UseContractWriteConfig<TMode, typeof quintABI, "transfer"> & {
        abi?: never;
        functionName?: "transfer";
      } = {} as any
) {
  return useContractWrite<TMode, typeof quintABI, "transfer">({
    abi: quintABI,
    address: quintAddress,
    functionName: "transfer",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link quintABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useQuintTransferFrom<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof quintABI, "transferFrom">["abi"],
        "transferFrom"
      > & { functionName?: "transferFrom" }
    : UseContractWriteConfig<TMode, typeof quintABI, "transferFrom"> & {
        abi?: never;
        functionName?: "transferFrom";
      } = {} as any
) {
  return useContractWrite<TMode, typeof quintABI, "transferFrom">({
    abi: quintABI,
    address: quintAddress,
    functionName: "transferFrom",
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link quintABI}__.
 */
export function usePrepareQuintWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof quintABI, TFunctionName>,
    "abi" | "address"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: quintABI,
    address: quintAddress,
    ...config,
  } as UsePrepareContractWriteConfig<typeof quintABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link quintABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareQuintApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof quintABI, "approve">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: quintABI,
    address: quintAddress,
    functionName: "approve",
    ...config,
  } as UsePrepareContractWriteConfig<typeof quintABI, "approve">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link quintABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareQuintTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof quintABI, "transfer">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: quintABI,
    address: quintAddress,
    functionName: "transfer",
    ...config,
  } as UsePrepareContractWriteConfig<typeof quintABI, "transfer">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link quintABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareQuintTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof quintABI, "transferFrom">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: quintABI,
    address: quintAddress,
    functionName: "transferFrom",
    ...config,
  } as UsePrepareContractWriteConfig<typeof quintABI, "transferFrom">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link quintABI}__.
 */
export function useQuintEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof quintABI, TEventName>,
    "abi" | "address"
  > = {} as any
) {
  return useContractEvent({
    abi: quintABI,
    address: quintAddress,
    ...config,
  } as UseContractEventConfig<typeof quintABI, TEventName>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link quintABI}__ and `eventName` set to `"Approval"`.
 */
export function useQuintApprovalEvent(
  config: Omit<
    UseContractEventConfig<typeof quintABI, "Approval">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: quintABI,
    address: quintAddress,
    eventName: "Approval",
    ...config,
  } as UseContractEventConfig<typeof quintABI, "Approval">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link quintABI}__ and `eventName` set to `"Transfer"`.
 */
export function useQuintTransferEvent(
  config: Omit<
    UseContractEventConfig<typeof quintABI, "Transfer">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: quintABI,
    address: quintAddress,
    eventName: "Transfer",
    ...config,
  } as UseContractEventConfig<typeof quintABI, "Transfer">);
}
