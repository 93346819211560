import { forwardRef, type HTMLAttributes, type SVGProps } from "react";
import { Check, Clock, SandClock } from "./icons";
import type { VariantProps } from "./utils";
import { cn, createVariants } from "./utils";

const chipVariants = createVariants(
  cn(
    "flex transition-all w-fit items-center h-[28px] p-[6px] pl-3 rounded-[24px] font-semibold text-[12px] gap-[10px]",
    "[&>svg]:h-[16px] [&>svg]:w-[16px]"
  ),
  {
    variants: {
      variant: {
        comingSoon: cn("text-[#123EA6] dark:text-blue-100 bg-[#E4EBF6] dark:bg-[#2D4C86]"),
        done: cn("text-[#06513B] dark:text-emerald-500 bg-[#DBF2F5] dark:bg-[#16464B]"),
        inProgress: cn("text-[#8C55C3] bg-[#E5D9F2] dark:text-[#BE9FDD] dark:bg-[#4D2673]"),
      },
    },
    defaultVariants: {
      variant: "done",
    },
  }
);

export interface ChipProps extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof chipVariants> {
  // icon?: "clock" | "check";
}

const variantIconMap: Record<NonNullable<ChipProps["variant"]>, (props: SVGProps<SVGSVGElement>) => JSX.Element> = {
  comingSoon: Clock,
  done: Check,
  inProgress: SandClock,
};

const Chip = forwardRef<HTMLDivElement, ChipProps>(({ className, variant, ...props }, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const Icon = variantIconMap[variant!];
  return (
    <div className={cn(chipVariants({ variant, className }))} ref={ref}>
      {props.children}
      {<Icon />}
    </div>
  );
});
Chip.displayName = "Chip";

export { Chip, chipVariants };
