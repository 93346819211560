import { ConnectButton } from "@rainbow-me/rainbowkit";

import { Button, Image, Loader } from "@quint/ui";

import { useQuintBalance } from "@quint/services/web3/hooks";
import { formatMoney } from "@quint/utils/format-money";

const ConnectWallet = () => {
  const balance = useQuintBalance();

  return (
    <ConnectButton.Custom>
      {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
        const ready = mounted;
        const connected = ready && account && chain;

        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
            className="flex flex-1"
          >
            {(() => {
              if (!connected) {
                return (
                  <Button variant="primaryGradient" onClick={openConnectModal}>
                    Connect Wallet
                  </Button>
                );
              }

              if (chain.unsupported) {
                return (
                  <Button variant="walletError" onClick={openChainModal}>
                    Wrong network
                  </Button>
                );
              }

              return (
                <Button
                  onClick={openAccountModal}
                  variant="wallet"
                  className="flex h-auto flex-1 sm:h-[46px] sm:flex-[unset]"
                  startIcon={
                    chain.hasIcon && chain.iconUrl ? (
                      <Image alt={chain.name ?? "Chain icon"} src={chain.iconUrl} width={30} height={30} />
                    ) : undefined
                  }
                >
                  <div className="flex flex-1 flex-col items-center sm:flex-row">
                    <span>{account.displayName}</span>
                    <div className="m-3 hidden h-[24px] w-[1px] bg-[#E6E8EA] dark:bg-[#354856] sm:block" />
                    <span>
                      {balance.isLoading ? <Loader /> : formatMoney(balance.data?.formatted || "")}{" "}
                      {balance.data?.symbol}
                    </span>
                  </div>
                </Button>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default ConnectWallet;
