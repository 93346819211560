import MainLogo from "../main-logo";
import ThemeToggler from "../theme-toggler";
import ConnectWallet from "./connect-wallet";
import MobileMenu from "./mobile-menu";
import Nav from "./nav";

const Header = () => {
  return (
    <header
      id="page-header"
      className="x-padding relative flex h-[96px] w-full items-center justify-between bg-transparent md:h-[120px]"
    >
      <div className="flex w-full items-center justify-between gap-10 sm:w-auto">
        <MainLogo />
        <div className="hidden lg:block">
          <Nav />
        </div>
      </div>
      <div className="lg:hidden">
        <MobileMenu />
      </div>
      <div className="hidden items-center gap-10 lg:flex">
        <ConnectWallet />
        <ThemeToggler />
      </div>
    </header>
  );
};

export default Header;
