// Generated by @wagmi/cli@0.1.11 on 2023-03-13 at 12:01:06 p.m.
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useContract,
  type UseContractConfig,
  useContractRead,
  type UseContractReadConfig,
  useContractWrite,
  type UseContractWriteConfig,
  usePrepareContractWrite,
  type UsePrepareContractWriteConfig,
  useContractEvent,
  type UseContractEventConfig,
} from "wagmi";
import {
  type ReadContractResult,
  type WriteContractMode,
  type PrepareWriteContractResult,
} from "wagmi/actions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// LuxuryPoolA
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const luxuryPoolAABI = [
  {
    stateMutability: "nonpayable",
    type: "constructor",
    inputs: [
      { name: "_owner", internalType: "address payable", type: "address" },
      {
        name: "_distributor",
        internalType: "address payable",
        type: "address",
      },
    ],
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "DEPOSITr",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "DEPOSIT",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "previousOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "newOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "OwnershipTransferred",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "DEPOSITr",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "WITHDRAW",
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      {
        name: "_distributor",
        internalType: "address payable",
        type: "address",
      },
    ],
    name: "ChangeDistributor",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_t1", internalType: "uint256", type: "uint256" },
      { name: "_t2", internalType: "uint256", type: "uint256" },
    ],
    name: "SetDurations",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_token", internalType: "uint256", type: "uint256" }],
    name: "SetMinAmount",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_token", internalType: "uint256", type: "uint256" },
      { name: "_divider", internalType: "uint256", type: "uint256" },
    ],
    name: "SetPoolsReward",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_tokens", internalType: "uint256", type: "uint256" }],
    name: "SetTicketsCount",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_amount", internalType: "uint256", type: "uint256" }],
    name: "calculateTokenReward",
    outputs: [{ name: "_reward", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_amount", internalType: "uint256", type: "uint256" }],
    name: "deposit",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "depositDuration",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "distributor",
    outputs: [{ name: "", internalType: "address payable", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "_user", internalType: "address", type: "address" },
      { name: "_index", internalType: "uint256", type: "uint256" },
    ],
    name: "getUserDepositInfo",
    outputs: [
      { name: "_amount", internalType: "uint256", type: "uint256" },
      { name: "_reward", internalType: "uint256", type: "uint256" },
      { name: "_tickets", internalType: "uint256", type: "uint256" },
      { name: "_time", internalType: "uint256", type: "uint256" },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_user", internalType: "address", type: "address" }],
    name: "getUserInfo",
    outputs: [
      { name: "_isExists", internalType: "bool", type: "bool" },
      { name: "_totalDeposit", internalType: "uint256", type: "uint256" },
      { name: "_totalTickets", internalType: "uint256", type: "uint256" },
      { name: "_totalReward", internalType: "uint256", type: "uint256" },
      { name: "_totalWithdrawn", internalType: "uint256", type: "uint256" },
      { name: "_isWithdrawn", internalType: "bool", type: "bool" },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_user", internalType: "address", type: "address" }],
    name: "getUserTotalDeposits",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "isPoolStart",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "minToken",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "owner",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "poolCloseTime",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "poolDuration",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "poolEndTime",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "poolStartTime",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "rewardDivider",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "startPool",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "ticketDivider",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "token",
    outputs: [{ name: "", internalType: "contract IERC20", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "tokenReward",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalDeposit",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalTickets",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalWithdrawn",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "newOwner", internalType: "address", type: "address" }],
    name: "transferOwnership",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "uniqueUsers",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "withdraw",
    outputs: [],
  },
] as const;

export const luxuryPoolAAddress =
  "0x97FCF60c0A5dC0B58344e0243B114ebdfE068DD2" as const;

export const luxuryPoolAConfig = {
  address: luxuryPoolAAddress,
  abi: luxuryPoolAABI,
} as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link luxuryPoolAABI}__.
 */
export function useLuxuryPoolA(
  config: Omit<UseContractConfig, "abi" | "address"> = {} as any
) {
  return useContract({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    ...config,
  });
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__.
 */
export function useLuxuryPoolARead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolAABI, TFunctionName, TSelectData>,
    "abi" | "address"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"calculateTokenReward"`.
 */
export function useLuxuryPoolACalculateTokenReward<
  TSelectData = ReadContractResult<
    typeof luxuryPoolAABI,
    "calculateTokenReward"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof luxuryPoolAABI,
      "calculateTokenReward",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "calculateTokenReward",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "calculateTokenReward", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"depositDuration"`.
 */
export function useLuxuryPoolADepositDuration<
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, "depositDuration">
>(
  config: Omit<
    UseContractReadConfig<
      typeof luxuryPoolAABI,
      "depositDuration",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "depositDuration",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "depositDuration", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"distributor"`.
 */
export function useLuxuryPoolADistributor<
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, "distributor">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolAABI, "distributor", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "distributor",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "distributor", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"getUserDepositInfo"`.
 */
export function useLuxuryPoolAGetUserDepositInfo<
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, "getUserDepositInfo">
>(
  config: Omit<
    UseContractReadConfig<
      typeof luxuryPoolAABI,
      "getUserDepositInfo",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "getUserDepositInfo",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "getUserDepositInfo", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"getUserInfo"`.
 */
export function useLuxuryPoolAGetUserInfo<
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, "getUserInfo">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolAABI, "getUserInfo", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "getUserInfo",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "getUserInfo", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"getUserTotalDeposits"`.
 */
export function useLuxuryPoolAGetUserTotalDeposits<
  TSelectData = ReadContractResult<
    typeof luxuryPoolAABI,
    "getUserTotalDeposits"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof luxuryPoolAABI,
      "getUserTotalDeposits",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "getUserTotalDeposits",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "getUserTotalDeposits", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"isPoolStart"`.
 */
export function useLuxuryPoolAIsPoolStart<
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, "isPoolStart">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolAABI, "isPoolStart", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "isPoolStart",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "isPoolStart", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"minToken"`.
 */
export function useLuxuryPoolAMinToken<
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, "minToken">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolAABI, "minToken", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "minToken",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "minToken", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"owner"`.
 */
export function useLuxuryPoolAOwner<
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, "owner">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolAABI, "owner", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "owner",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "owner", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"poolCloseTime"`.
 */
export function useLuxuryPoolAPoolCloseTime<
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, "poolCloseTime">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolAABI, "poolCloseTime", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "poolCloseTime",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "poolCloseTime", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"poolDuration"`.
 */
export function useLuxuryPoolAPoolDuration<
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, "poolDuration">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolAABI, "poolDuration", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "poolDuration",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "poolDuration", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"poolEndTime"`.
 */
export function useLuxuryPoolAPoolEndTime<
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, "poolEndTime">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolAABI, "poolEndTime", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "poolEndTime",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "poolEndTime", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"poolStartTime"`.
 */
export function useLuxuryPoolAPoolStartTime<
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, "poolStartTime">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolAABI, "poolStartTime", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "poolStartTime",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "poolStartTime", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"rewardDivider"`.
 */
export function useLuxuryPoolARewardDivider<
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, "rewardDivider">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolAABI, "rewardDivider", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "rewardDivider",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "rewardDivider", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"ticketDivider"`.
 */
export function useLuxuryPoolATicketDivider<
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, "ticketDivider">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolAABI, "ticketDivider", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "ticketDivider",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "ticketDivider", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"token"`.
 */
export function useLuxuryPoolAToken<
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, "token">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolAABI, "token", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "token",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "token", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"tokenReward"`.
 */
export function useLuxuryPoolATokenReward<
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, "tokenReward">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolAABI, "tokenReward", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "tokenReward",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "tokenReward", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"totalDeposit"`.
 */
export function useLuxuryPoolATotalDeposit<
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, "totalDeposit">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolAABI, "totalDeposit", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "totalDeposit",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "totalDeposit", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"totalTickets"`.
 */
export function useLuxuryPoolATotalTickets<
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, "totalTickets">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolAABI, "totalTickets", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "totalTickets",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "totalTickets", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"totalWithdrawn"`.
 */
export function useLuxuryPoolATotalWithdrawn<
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, "totalWithdrawn">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolAABI, "totalWithdrawn", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "totalWithdrawn",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "totalWithdrawn", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"uniqueUsers"`.
 */
export function useLuxuryPoolAUniqueUsers<
  TSelectData = ReadContractResult<typeof luxuryPoolAABI, "uniqueUsers">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolAABI, "uniqueUsers", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "uniqueUsers",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolAABI, "uniqueUsers", TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__.
 */
export function useLuxuryPoolAWrite<
  TMode extends WriteContractMode,
  TFunctionName extends string
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof luxuryPoolAABI, string>["abi"],
        TFunctionName
      >
    : UseContractWriteConfig<TMode, typeof luxuryPoolAABI, TFunctionName> & {
        abi?: never;
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolAABI, TFunctionName>({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"ChangeDistributor"`.
 */
export function useLuxuryPoolAChangeDistributor<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof luxuryPoolAABI,
          "ChangeDistributor"
        >["abi"],
        "ChangeDistributor"
      > & { functionName?: "ChangeDistributor" }
    : UseContractWriteConfig<
        TMode,
        typeof luxuryPoolAABI,
        "ChangeDistributor"
      > & {
        abi?: never;
        functionName?: "ChangeDistributor";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolAABI, "ChangeDistributor">({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "ChangeDistributor",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"SetDurations"`.
 */
export function useLuxuryPoolASetDurations<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof luxuryPoolAABI,
          "SetDurations"
        >["abi"],
        "SetDurations"
      > & { functionName?: "SetDurations" }
    : UseContractWriteConfig<TMode, typeof luxuryPoolAABI, "SetDurations"> & {
        abi?: never;
        functionName?: "SetDurations";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolAABI, "SetDurations">({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "SetDurations",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"SetMinAmount"`.
 */
export function useLuxuryPoolASetMinAmount<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof luxuryPoolAABI,
          "SetMinAmount"
        >["abi"],
        "SetMinAmount"
      > & { functionName?: "SetMinAmount" }
    : UseContractWriteConfig<TMode, typeof luxuryPoolAABI, "SetMinAmount"> & {
        abi?: never;
        functionName?: "SetMinAmount";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolAABI, "SetMinAmount">({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "SetMinAmount",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"SetPoolsReward"`.
 */
export function useLuxuryPoolASetPoolsReward<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof luxuryPoolAABI,
          "SetPoolsReward"
        >["abi"],
        "SetPoolsReward"
      > & { functionName?: "SetPoolsReward" }
    : UseContractWriteConfig<TMode, typeof luxuryPoolAABI, "SetPoolsReward"> & {
        abi?: never;
        functionName?: "SetPoolsReward";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolAABI, "SetPoolsReward">({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "SetPoolsReward",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"SetTicketsCount"`.
 */
export function useLuxuryPoolASetTicketsCount<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof luxuryPoolAABI,
          "SetTicketsCount"
        >["abi"],
        "SetTicketsCount"
      > & { functionName?: "SetTicketsCount" }
    : UseContractWriteConfig<
        TMode,
        typeof luxuryPoolAABI,
        "SetTicketsCount"
      > & {
        abi?: never;
        functionName?: "SetTicketsCount";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolAABI, "SetTicketsCount">({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "SetTicketsCount",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"deposit"`.
 */
export function useLuxuryPoolADeposit<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof luxuryPoolAABI, "deposit">["abi"],
        "deposit"
      > & { functionName?: "deposit" }
    : UseContractWriteConfig<TMode, typeof luxuryPoolAABI, "deposit"> & {
        abi?: never;
        functionName?: "deposit";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolAABI, "deposit">({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "deposit",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useLuxuryPoolARenounceOwnership<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof luxuryPoolAABI,
          "renounceOwnership"
        >["abi"],
        "renounceOwnership"
      > & { functionName?: "renounceOwnership" }
    : UseContractWriteConfig<
        TMode,
        typeof luxuryPoolAABI,
        "renounceOwnership"
      > & {
        abi?: never;
        functionName?: "renounceOwnership";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolAABI, "renounceOwnership">({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "renounceOwnership",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"startPool"`.
 */
export function useLuxuryPoolAStartPool<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof luxuryPoolAABI, "startPool">["abi"],
        "startPool"
      > & { functionName?: "startPool" }
    : UseContractWriteConfig<TMode, typeof luxuryPoolAABI, "startPool"> & {
        abi?: never;
        functionName?: "startPool";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolAABI, "startPool">({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "startPool",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useLuxuryPoolATransferOwnership<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof luxuryPoolAABI,
          "transferOwnership"
        >["abi"],
        "transferOwnership"
      > & { functionName?: "transferOwnership" }
    : UseContractWriteConfig<
        TMode,
        typeof luxuryPoolAABI,
        "transferOwnership"
      > & {
        abi?: never;
        functionName?: "transferOwnership";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolAABI, "transferOwnership">({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "transferOwnership",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"withdraw"`.
 */
export function useLuxuryPoolAWithdraw<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof luxuryPoolAABI, "withdraw">["abi"],
        "withdraw"
      > & { functionName?: "withdraw" }
    : UseContractWriteConfig<TMode, typeof luxuryPoolAABI, "withdraw"> & {
        abi?: never;
        functionName?: "withdraw";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolAABI, "withdraw">({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "withdraw",
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__.
 */
export function usePrepareLuxuryPoolAWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolAABI, TFunctionName>,
    "abi" | "address"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolAABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"ChangeDistributor"`.
 */
export function usePrepareLuxuryPoolAChangeDistributor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "ChangeDistributor">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "ChangeDistributor",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "ChangeDistributor">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"SetDurations"`.
 */
export function usePrepareLuxuryPoolASetDurations(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "SetDurations">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "SetDurations",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "SetDurations">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"SetMinAmount"`.
 */
export function usePrepareLuxuryPoolASetMinAmount(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "SetMinAmount">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "SetMinAmount",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "SetMinAmount">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"SetPoolsReward"`.
 */
export function usePrepareLuxuryPoolASetPoolsReward(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "SetPoolsReward">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "SetPoolsReward",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "SetPoolsReward">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"SetTicketsCount"`.
 */
export function usePrepareLuxuryPoolASetTicketsCount(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "SetTicketsCount">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "SetTicketsCount",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "SetTicketsCount">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"deposit"`.
 */
export function usePrepareLuxuryPoolADeposit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "deposit">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "deposit",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "deposit">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareLuxuryPoolARenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "renounceOwnership">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "renounceOwnership",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "renounceOwnership">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"startPool"`.
 */
export function usePrepareLuxuryPoolAStartPool(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "startPool">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "startPool",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "startPool">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareLuxuryPoolATransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "transferOwnership">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "transferOwnership",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "transferOwnership">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolAABI}__ and `functionName` set to `"withdraw"`.
 */
export function usePrepareLuxuryPoolAWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "withdraw">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    functionName: "withdraw",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolAABI, "withdraw">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link luxuryPoolAABI}__.
 */
export function useLuxuryPoolAEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof luxuryPoolAABI, TEventName>,
    "abi" | "address"
  > = {} as any
) {
  return useContractEvent({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    ...config,
  } as UseContractEventConfig<typeof luxuryPoolAABI, TEventName>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link luxuryPoolAABI}__ and `eventName` set to `"DEPOSIT"`.
 */
export function useLuxuryPoolADepositEvent(
  config: Omit<
    UseContractEventConfig<typeof luxuryPoolAABI, "DEPOSIT">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    eventName: "DEPOSIT",
    ...config,
  } as UseContractEventConfig<typeof luxuryPoolAABI, "DEPOSIT">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link luxuryPoolAABI}__ and `eventName` set to `"OwnershipTransferred"`.
 */
export function useLuxuryPoolAOwnershipTransferredEvent(
  config: Omit<
    UseContractEventConfig<typeof luxuryPoolAABI, "OwnershipTransferred">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    eventName: "OwnershipTransferred",
    ...config,
  } as UseContractEventConfig<typeof luxuryPoolAABI, "OwnershipTransferred">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link luxuryPoolAABI}__ and `eventName` set to `"WITHDRAW"`.
 */
export function useLuxuryPoolAWithdrawEvent(
  config: Omit<
    UseContractEventConfig<typeof luxuryPoolAABI, "WITHDRAW">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: luxuryPoolAABI,
    address: luxuryPoolAAddress,
    eventName: "WITHDRAW",
    ...config,
  } as UseContractEventConfig<typeof luxuryPoolAABI, "WITHDRAW">);
}
