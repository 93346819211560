const truncateFractionAndFormat = (parts: Intl.NumberFormatPart[], digits: number) => {
  return parts
    .map(({ type, value }) => {
      if (type !== 'fraction' || !value || value.length < digits) {
        return value;
      }

      let retVal = '';
      for (let idx = 0, counter = 0; idx < value.length && counter < digits; idx++) {
        if (value[idx] !== '0') {
          counter++;
        }
        retVal += value[idx];
      }
      return retVal;
    })
    .reduce((string, part) => string + part);
};

export const formatMoney = (value: string | number, digits = 5) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: digits,
  });

  try {
    return truncateFractionAndFormat(formatter.formatToParts(value as number), 4);
  } catch (err) {
    return 0;
  }
};
