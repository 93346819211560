"use client";

import { forwardRef, type InputHTMLAttributes, type ReactNode } from "react";
import { cn } from "./utils";

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  endAdornment?: ReactNode;
  number?: boolean;
};

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, endAdornment, number, ...props }, ref) => {
  return (
    <div
      className={cn(
        "flex h-10 w-full rounded-md bg-[#E9F0F4] text-sm placeholder:text-slate-400 disabled:cursor-not-allowed disabled:opacity-50 dark:bg-[#102D42] dark:text-slate-50",
        "[&:focus-within]:ring-2 [&:focus-within]:ring-slate-400 [&:focus-within]:ring-offset-2 dark:[&:focus-within]:ring-offset-slate-900",
        className
      )}
    >
      <input
        ref={ref}
        {...props}
        className="flex w-full flex-grow rounded-md bg-[#E9F0F4] py-2 px-3 dark:bg-[#102D42] [&:focus-visible]:outline-none"
        {...(number && { type: "number" })}
      />

      {endAdornment && <div className="flex h-full items-center py-2 px-3">{endAdornment}</div>}
    </div>
  );
});

Input.displayName = "Input";

export { Input };
