import Discord from "@quint/ui/icons/social/discord";
import Facebook from "@quint/ui/icons/social/facebook";
import Twitter from "@quint/ui/icons/social/twitter";
import Telegram from "@quint/ui/icons/social/telegram";
import Instagram from "@quint/ui/icons/social/instagram";
const socialItems = [
  {
    url: "http://t.me/projectquint",
    description: "Quint on Telegram",
    icon: <Telegram />,
  },
  {
    url: "https://discord.gg/kYVQeSBxAk",
    description: "Quint on Discord",
    icon: <Discord />,
  },
  {
    url: "https://www.facebook.com/projectquint/",
    description: "Quint on Facebook",
    icon: <Facebook />,
  },

  {
    url: "https://twitter.com/projectquint",
    description: "Quint on Twitter",
    icon: <Twitter />,
  },
  {
    url: "https://www.instagram.com/project.quint/",
    description: "Quint on Instagram",
    icon: <Instagram />,
  },
];

const SocialRow = () => {
  return (
    <ul className="flex items-center gap-6">
      {socialItems.map((item) => (
        <li key={item.url}>
          <a
            className="flex h-[24px] w-[24px] items-center justify-center"
            href={item.url}
          >
            <span className="sr-only">{item.description}</span>
            {item.icon}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default SocialRow;
