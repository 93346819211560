// Generated by @wagmi/cli@0.1.11 on 2023-03-13 at 12:01:05 p.m.
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useContract,
  type UseContractConfig,
  useContractRead,
  type UseContractReadConfig,
  useContractWrite,
  type UseContractWriteConfig,
  usePrepareContractWrite,
  type UsePrepareContractWriteConfig,
  useContractEvent,
  type UseContractEventConfig,
} from "wagmi";
import {
  type ReadContractResult,
  type WriteContractMode,
  type PrepareWriteContractResult,
} from "wagmi/actions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ConventionalPoolV2
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const conventionalPoolV2ABI = [
  {
    stateMutability: "nonpayable",
    type: "constructor",
    inputs: [
      {
        name: "initialOwner",
        internalType: "address payable",
        type: "address",
      },
      { name: "rewardToken", internalType: "address", type: "address" },
      { name: "distributor", internalType: "address payable", type: "address" },
      { name: "legacyPool", internalType: "address", type: "address" },
    ],
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "staker",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      { name: "isBanned", internalType: "bool", type: "bool", indexed: false },
    ],
    name: "Banned",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "isEnabled", internalType: "bool", type: "bool", indexed: false },
    ],
    name: "EmergencyWithdrawalEnabled",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "staker",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "tokenAmount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "stakedAt",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "rewardedAt",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "EmergencyWithdrawn",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "staker",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      { name: "isExempt", internalType: "bool", type: "bool", indexed: false },
    ],
    name: "Exempted",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "staker",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "rewards",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "Harvested",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "staker",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: true,
      },
    ],
    name: "Migrated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "previousOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "newOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "OwnershipTransferred",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "isPaused", internalType: "bool", type: "bool", indexed: false },
    ],
    name: "Paused",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "staker",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "Staked",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "staker",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "Unstaked",
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "QUINT_TOKEN",
    outputs: [{ name: "", internalType: "contract IERC20", type: "address" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "account", internalType: "address", type: "address" },
      { name: "isExempt", internalType: "bool", type: "bool" },
    ],
    name: "SetExemptFromTaxation",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      {
        name: "newMinimumStakingAmount",
        internalType: "uint256",
        type: "uint256",
      },
    ],
    name: "SetMinimumStakingAmount",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "newRewardMultiplier", internalType: "uint256", type: "uint256" },
      { name: "newRewardDivider", internalType: "uint256", type: "uint256" },
    ],
    name: "SetPoolAPYSettings",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      {
        name: "newUnstakeTaxPercentage",
        internalType: "uint256",
        type: "uint256",
      },
      {
        name: "newUnstakeTaxRemovedAt",
        internalType: "uint256",
        type: "uint256",
      },
    ],
    name: "SetUnstakeTaxAndDuration",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "address", type: "address" }],
    name: "accountDetails",
    outputs: [
      { name: "totalStakedTokens", internalType: "uint256", type: "uint256" },
      { name: "totalUnstakedTokens", internalType: "uint256", type: "uint256" },
      { name: "totalStakeEntries", internalType: "uint256", type: "uint256" },
      {
        name: "totalHarvestedRewards",
        internalType: "uint256",
        type: "uint256",
      },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "account", internalType: "address", type: "address" }],
    name: "calculateTokenReward",
    outputs: [{ name: "reward", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "emergencyWithdraw",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "harvest",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "address", type: "address" }],
    name: "isBanned",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "isEmergencyWithdrawEnabled",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "address", type: "address" }],
    name: "isExemptFromTaxation",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "address", type: "address" }],
    name: "isMigrated",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "isPaused",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "legacyStakingPool",
    outputs: [
      {
        name: "",
        internalType: "contract IQuintConventionalPool",
        type: "address",
      },
    ],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "migrate",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "minimumStakingAmount",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "owner",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "rewardDivider",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "rewardMultiplier",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "rewardVault",
    outputs: [{ name: "", internalType: "address payable", type: "address" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "state", internalType: "bool", type: "bool" }],
    name: "setAllowEmergencyWithdraw",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "account", internalType: "address", type: "address" },
      { name: "state", internalType: "bool", type: "bool" },
    ],
    name: "setBanState",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "state", internalType: "bool", type: "bool" }],
    name: "setPoolPauseState",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      {
        name: "newRewardVault",
        internalType: "address payable",
        type: "address",
      },
    ],
    name: "setRewardVault",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "amount", internalType: "uint256", type: "uint256" }],
    name: "stake",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "address", type: "address" }],
    name: "stakingDetails",
    outputs: [
      { name: "stakedAt", internalType: "uint256", type: "uint256" },
      { name: "tokenAmount", internalType: "uint256", type: "uint256" },
      { name: "rewardedAt", internalType: "uint256", type: "uint256" },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalHarvestedRewards",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalStakedTokens",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalUniqueStakers",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalUnstakedTokens",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "newOwner", internalType: "address", type: "address" }],
    name: "transferOwnership",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "unstake",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "amount", internalType: "uint256", type: "uint256" }],
    name: "unstakePartial",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "unstakeTaxPercentage",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "unstakeTaxRemovedAt",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
] as const;

export const conventionalPoolV2Address =
  "0x14E6A433567fB1f90f60074D63405d34CAdD5d41" as const;

export const conventionalPoolV2Config = {
  address: conventionalPoolV2Address,
  abi: conventionalPoolV2ABI,
} as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link conventionalPoolV2ABI}__.
 */
export function useConventionalPoolV2(
  config: Omit<UseContractConfig, "abi" | "address"> = {} as any
) {
  return useContract({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    ...config,
  });
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__.
 */
export function useConventionalPoolV2Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof conventionalPoolV2ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      TFunctionName,
      TSelectData
    >,
    "abi" | "address"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"QUINT_TOKEN"`.
 */
export function useConventionalPoolV2QuintToken<
  TSelectData = ReadContractResult<typeof conventionalPoolV2ABI, "QUINT_TOKEN">
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "QUINT_TOKEN",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "QUINT_TOKEN",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "QUINT_TOKEN", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"accountDetails"`.
 */
export function useConventionalPoolV2AccountDetails<
  TSelectData = ReadContractResult<
    typeof conventionalPoolV2ABI,
    "accountDetails"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "accountDetails",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "accountDetails",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "accountDetails", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"calculateTokenReward"`.
 */
export function useConventionalPoolV2CalculateTokenReward<
  TSelectData = ReadContractResult<
    typeof conventionalPoolV2ABI,
    "calculateTokenReward"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "calculateTokenReward",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "calculateTokenReward",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "calculateTokenReward", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"isBanned"`.
 */
export function useConventionalPoolV2IsBanned<
  TSelectData = ReadContractResult<typeof conventionalPoolV2ABI, "isBanned">
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "isBanned",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "isBanned",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "isBanned", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"isEmergencyWithdrawEnabled"`.
 */
export function useConventionalPoolV2IsEmergencyWithdrawEnabled<
  TSelectData = ReadContractResult<
    typeof conventionalPoolV2ABI,
    "isEmergencyWithdrawEnabled"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "isEmergencyWithdrawEnabled",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "isEmergencyWithdrawEnabled",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "isEmergencyWithdrawEnabled", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"isExemptFromTaxation"`.
 */
export function useConventionalPoolV2IsExemptFromTaxation<
  TSelectData = ReadContractResult<
    typeof conventionalPoolV2ABI,
    "isExemptFromTaxation"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "isExemptFromTaxation",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "isExemptFromTaxation",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "isExemptFromTaxation", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"isMigrated"`.
 */
export function useConventionalPoolV2IsMigrated<
  TSelectData = ReadContractResult<typeof conventionalPoolV2ABI, "isMigrated">
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "isMigrated",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "isMigrated",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "isMigrated", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"isPaused"`.
 */
export function useConventionalPoolV2IsPaused<
  TSelectData = ReadContractResult<typeof conventionalPoolV2ABI, "isPaused">
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "isPaused",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "isPaused",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "isPaused", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"legacyStakingPool"`.
 */
export function useConventionalPoolV2LegacyStakingPool<
  TSelectData = ReadContractResult<
    typeof conventionalPoolV2ABI,
    "legacyStakingPool"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "legacyStakingPool",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "legacyStakingPool",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "legacyStakingPool", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"minimumStakingAmount"`.
 */
export function useConventionalPoolV2MinimumStakingAmount<
  TSelectData = ReadContractResult<
    typeof conventionalPoolV2ABI,
    "minimumStakingAmount"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "minimumStakingAmount",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "minimumStakingAmount",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "minimumStakingAmount", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"owner"`.
 */
export function useConventionalPoolV2Owner<
  TSelectData = ReadContractResult<typeof conventionalPoolV2ABI, "owner">
>(
  config: Omit<
    UseContractReadConfig<typeof conventionalPoolV2ABI, "owner", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "owner",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "owner", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"rewardDivider"`.
 */
export function useConventionalPoolV2RewardDivider<
  TSelectData = ReadContractResult<
    typeof conventionalPoolV2ABI,
    "rewardDivider"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "rewardDivider",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "rewardDivider",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "rewardDivider", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"rewardMultiplier"`.
 */
export function useConventionalPoolV2RewardMultiplier<
  TSelectData = ReadContractResult<
    typeof conventionalPoolV2ABI,
    "rewardMultiplier"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "rewardMultiplier",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "rewardMultiplier",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "rewardMultiplier", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"rewardVault"`.
 */
export function useConventionalPoolV2RewardVault<
  TSelectData = ReadContractResult<typeof conventionalPoolV2ABI, "rewardVault">
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "rewardVault",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "rewardVault",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "rewardVault", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"stakingDetails"`.
 */
export function useConventionalPoolV2StakingDetails<
  TSelectData = ReadContractResult<
    typeof conventionalPoolV2ABI,
    "stakingDetails"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "stakingDetails",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "stakingDetails",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "stakingDetails", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"totalHarvestedRewards"`.
 */
export function useConventionalPoolV2TotalHarvestedRewards<
  TSelectData = ReadContractResult<
    typeof conventionalPoolV2ABI,
    "totalHarvestedRewards"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "totalHarvestedRewards",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "totalHarvestedRewards",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "totalHarvestedRewards", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"totalStakedTokens"`.
 */
export function useConventionalPoolV2TotalStakedTokens<
  TSelectData = ReadContractResult<
    typeof conventionalPoolV2ABI,
    "totalStakedTokens"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "totalStakedTokens",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "totalStakedTokens",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "totalStakedTokens", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"totalUniqueStakers"`.
 */
export function useConventionalPoolV2TotalUniqueStakers<
  TSelectData = ReadContractResult<
    typeof conventionalPoolV2ABI,
    "totalUniqueStakers"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "totalUniqueStakers",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "totalUniqueStakers",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "totalUniqueStakers", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"totalUnstakedTokens"`.
 */
export function useConventionalPoolV2TotalUnstakedTokens<
  TSelectData = ReadContractResult<
    typeof conventionalPoolV2ABI,
    "totalUnstakedTokens"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "totalUnstakedTokens",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "totalUnstakedTokens",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "totalUnstakedTokens", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"unstakeTaxPercentage"`.
 */
export function useConventionalPoolV2UnstakeTaxPercentage<
  TSelectData = ReadContractResult<
    typeof conventionalPoolV2ABI,
    "unstakeTaxPercentage"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "unstakeTaxPercentage",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "unstakeTaxPercentage",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "unstakeTaxPercentage", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"unstakeTaxRemovedAt"`.
 */
export function useConventionalPoolV2UnstakeTaxRemovedAt<
  TSelectData = ReadContractResult<
    typeof conventionalPoolV2ABI,
    "unstakeTaxRemovedAt"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalPoolV2ABI,
      "unstakeTaxRemovedAt",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "unstakeTaxRemovedAt",
    ...config,
  } as UseContractReadConfig<typeof conventionalPoolV2ABI, "unstakeTaxRemovedAt", TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__.
 */
export function useConventionalPoolV2Write<
  TMode extends WriteContractMode,
  TFunctionName extends string
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof conventionalPoolV2ABI, string>["abi"],
        TFunctionName
      >
    : UseContractWriteConfig<
        TMode,
        typeof conventionalPoolV2ABI,
        TFunctionName
      > & {
        abi?: never;
      } = {} as any
) {
  return useContractWrite<TMode, typeof conventionalPoolV2ABI, TFunctionName>({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"SetExemptFromTaxation"`.
 */
export function useConventionalPoolV2SetExemptFromTaxation<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalPoolV2ABI,
          "SetExemptFromTaxation"
        >["abi"],
        "SetExemptFromTaxation"
      > & { functionName?: "SetExemptFromTaxation" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalPoolV2ABI,
        "SetExemptFromTaxation"
      > & {
        abi?: never;
        functionName?: "SetExemptFromTaxation";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalPoolV2ABI,
    "SetExemptFromTaxation"
  >({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "SetExemptFromTaxation",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"SetMinimumStakingAmount"`.
 */
export function useConventionalPoolV2SetMinimumStakingAmount<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalPoolV2ABI,
          "SetMinimumStakingAmount"
        >["abi"],
        "SetMinimumStakingAmount"
      > & { functionName?: "SetMinimumStakingAmount" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalPoolV2ABI,
        "SetMinimumStakingAmount"
      > & {
        abi?: never;
        functionName?: "SetMinimumStakingAmount";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalPoolV2ABI,
    "SetMinimumStakingAmount"
  >({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "SetMinimumStakingAmount",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"SetPoolAPYSettings"`.
 */
export function useConventionalPoolV2SetPoolApySettings<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalPoolV2ABI,
          "SetPoolAPYSettings"
        >["abi"],
        "SetPoolAPYSettings"
      > & { functionName?: "SetPoolAPYSettings" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalPoolV2ABI,
        "SetPoolAPYSettings"
      > & {
        abi?: never;
        functionName?: "SetPoolAPYSettings";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalPoolV2ABI,
    "SetPoolAPYSettings"
  >({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "SetPoolAPYSettings",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"SetUnstakeTaxAndDuration"`.
 */
export function useConventionalPoolV2SetUnstakeTaxAndDuration<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalPoolV2ABI,
          "SetUnstakeTaxAndDuration"
        >["abi"],
        "SetUnstakeTaxAndDuration"
      > & { functionName?: "SetUnstakeTaxAndDuration" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalPoolV2ABI,
        "SetUnstakeTaxAndDuration"
      > & {
        abi?: never;
        functionName?: "SetUnstakeTaxAndDuration";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalPoolV2ABI,
    "SetUnstakeTaxAndDuration"
  >({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "SetUnstakeTaxAndDuration",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"emergencyWithdraw"`.
 */
export function useConventionalPoolV2EmergencyWithdraw<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalPoolV2ABI,
          "emergencyWithdraw"
        >["abi"],
        "emergencyWithdraw"
      > & { functionName?: "emergencyWithdraw" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalPoolV2ABI,
        "emergencyWithdraw"
      > & {
        abi?: never;
        functionName?: "emergencyWithdraw";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalPoolV2ABI,
    "emergencyWithdraw"
  >({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "emergencyWithdraw",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"harvest"`.
 */
export function useConventionalPoolV2Harvest<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalPoolV2ABI,
          "harvest"
        >["abi"],
        "harvest"
      > & { functionName?: "harvest" }
    : UseContractWriteConfig<TMode, typeof conventionalPoolV2ABI, "harvest"> & {
        abi?: never;
        functionName?: "harvest";
      } = {} as any
) {
  return useContractWrite<TMode, typeof conventionalPoolV2ABI, "harvest">({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "harvest",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"migrate"`.
 */
export function useConventionalPoolV2Migrate<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalPoolV2ABI,
          "migrate"
        >["abi"],
        "migrate"
      > & { functionName?: "migrate" }
    : UseContractWriteConfig<TMode, typeof conventionalPoolV2ABI, "migrate"> & {
        abi?: never;
        functionName?: "migrate";
      } = {} as any
) {
  return useContractWrite<TMode, typeof conventionalPoolV2ABI, "migrate">({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "migrate",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useConventionalPoolV2RenounceOwnership<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalPoolV2ABI,
          "renounceOwnership"
        >["abi"],
        "renounceOwnership"
      > & { functionName?: "renounceOwnership" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalPoolV2ABI,
        "renounceOwnership"
      > & {
        abi?: never;
        functionName?: "renounceOwnership";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalPoolV2ABI,
    "renounceOwnership"
  >({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "renounceOwnership",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"setAllowEmergencyWithdraw"`.
 */
export function useConventionalPoolV2SetAllowEmergencyWithdraw<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalPoolV2ABI,
          "setAllowEmergencyWithdraw"
        >["abi"],
        "setAllowEmergencyWithdraw"
      > & { functionName?: "setAllowEmergencyWithdraw" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalPoolV2ABI,
        "setAllowEmergencyWithdraw"
      > & {
        abi?: never;
        functionName?: "setAllowEmergencyWithdraw";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalPoolV2ABI,
    "setAllowEmergencyWithdraw"
  >({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "setAllowEmergencyWithdraw",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"setBanState"`.
 */
export function useConventionalPoolV2SetBanState<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalPoolV2ABI,
          "setBanState"
        >["abi"],
        "setBanState"
      > & { functionName?: "setBanState" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalPoolV2ABI,
        "setBanState"
      > & {
        abi?: never;
        functionName?: "setBanState";
      } = {} as any
) {
  return useContractWrite<TMode, typeof conventionalPoolV2ABI, "setBanState">({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "setBanState",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"setPoolPauseState"`.
 */
export function useConventionalPoolV2SetPoolPauseState<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalPoolV2ABI,
          "setPoolPauseState"
        >["abi"],
        "setPoolPauseState"
      > & { functionName?: "setPoolPauseState" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalPoolV2ABI,
        "setPoolPauseState"
      > & {
        abi?: never;
        functionName?: "setPoolPauseState";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalPoolV2ABI,
    "setPoolPauseState"
  >({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "setPoolPauseState",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"setRewardVault"`.
 */
export function useConventionalPoolV2SetRewardVault<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalPoolV2ABI,
          "setRewardVault"
        >["abi"],
        "setRewardVault"
      > & { functionName?: "setRewardVault" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalPoolV2ABI,
        "setRewardVault"
      > & {
        abi?: never;
        functionName?: "setRewardVault";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalPoolV2ABI,
    "setRewardVault"
  >({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "setRewardVault",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"stake"`.
 */
export function useConventionalPoolV2Stake<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalPoolV2ABI,
          "stake"
        >["abi"],
        "stake"
      > & { functionName?: "stake" }
    : UseContractWriteConfig<TMode, typeof conventionalPoolV2ABI, "stake"> & {
        abi?: never;
        functionName?: "stake";
      } = {} as any
) {
  return useContractWrite<TMode, typeof conventionalPoolV2ABI, "stake">({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "stake",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useConventionalPoolV2TransferOwnership<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalPoolV2ABI,
          "transferOwnership"
        >["abi"],
        "transferOwnership"
      > & { functionName?: "transferOwnership" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalPoolV2ABI,
        "transferOwnership"
      > & {
        abi?: never;
        functionName?: "transferOwnership";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalPoolV2ABI,
    "transferOwnership"
  >({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "transferOwnership",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"unstake"`.
 */
export function useConventionalPoolV2Unstake<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalPoolV2ABI,
          "unstake"
        >["abi"],
        "unstake"
      > & { functionName?: "unstake" }
    : UseContractWriteConfig<TMode, typeof conventionalPoolV2ABI, "unstake"> & {
        abi?: never;
        functionName?: "unstake";
      } = {} as any
) {
  return useContractWrite<TMode, typeof conventionalPoolV2ABI, "unstake">({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "unstake",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"unstakePartial"`.
 */
export function useConventionalPoolV2UnstakePartial<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalPoolV2ABI,
          "unstakePartial"
        >["abi"],
        "unstakePartial"
      > & { functionName?: "unstakePartial" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalPoolV2ABI,
        "unstakePartial"
      > & {
        abi?: never;
        functionName?: "unstakePartial";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalPoolV2ABI,
    "unstakePartial"
  >({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "unstakePartial",
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__.
 */
export function usePrepareConventionalPoolV2Write<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, TFunctionName>,
    "abi" | "address"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"SetExemptFromTaxation"`.
 */
export function usePrepareConventionalPoolV2SetExemptFromTaxation(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalPoolV2ABI,
      "SetExemptFromTaxation"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "SetExemptFromTaxation",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "SetExemptFromTaxation">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"SetMinimumStakingAmount"`.
 */
export function usePrepareConventionalPoolV2SetMinimumStakingAmount(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalPoolV2ABI,
      "SetMinimumStakingAmount"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "SetMinimumStakingAmount",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "SetMinimumStakingAmount">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"SetPoolAPYSettings"`.
 */
export function usePrepareConventionalPoolV2SetPoolApySettings(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalPoolV2ABI,
      "SetPoolAPYSettings"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "SetPoolAPYSettings",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "SetPoolAPYSettings">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"SetUnstakeTaxAndDuration"`.
 */
export function usePrepareConventionalPoolV2SetUnstakeTaxAndDuration(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalPoolV2ABI,
      "SetUnstakeTaxAndDuration"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "SetUnstakeTaxAndDuration",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "SetUnstakeTaxAndDuration">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"emergencyWithdraw"`.
 */
export function usePrepareConventionalPoolV2EmergencyWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalPoolV2ABI,
      "emergencyWithdraw"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "emergencyWithdraw",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "emergencyWithdraw">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"harvest"`.
 */
export function usePrepareConventionalPoolV2Harvest(
  config: Omit<
    UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "harvest">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "harvest",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "harvest">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"migrate"`.
 */
export function usePrepareConventionalPoolV2Migrate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "migrate">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "migrate",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "migrate">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareConventionalPoolV2RenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalPoolV2ABI,
      "renounceOwnership"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "renounceOwnership",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "renounceOwnership">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"setAllowEmergencyWithdraw"`.
 */
export function usePrepareConventionalPoolV2SetAllowEmergencyWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalPoolV2ABI,
      "setAllowEmergencyWithdraw"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "setAllowEmergencyWithdraw",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "setAllowEmergencyWithdraw">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"setBanState"`.
 */
export function usePrepareConventionalPoolV2SetBanState(
  config: Omit<
    UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "setBanState">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "setBanState",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "setBanState">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"setPoolPauseState"`.
 */
export function usePrepareConventionalPoolV2SetPoolPauseState(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalPoolV2ABI,
      "setPoolPauseState"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "setPoolPauseState",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "setPoolPauseState">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"setRewardVault"`.
 */
export function usePrepareConventionalPoolV2SetRewardVault(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalPoolV2ABI,
      "setRewardVault"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "setRewardVault",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "setRewardVault">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"stake"`.
 */
export function usePrepareConventionalPoolV2Stake(
  config: Omit<
    UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "stake">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "stake",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "stake">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareConventionalPoolV2TransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalPoolV2ABI,
      "transferOwnership"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "transferOwnership",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "transferOwnership">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"unstake"`.
 */
export function usePrepareConventionalPoolV2Unstake(
  config: Omit<
    UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "unstake">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "unstake",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "unstake">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `functionName` set to `"unstakePartial"`.
 */
export function usePrepareConventionalPoolV2UnstakePartial(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalPoolV2ABI,
      "unstakePartial"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    functionName: "unstakePartial",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalPoolV2ABI, "unstakePartial">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalPoolV2ABI}__.
 */
export function useConventionalPoolV2Event<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof conventionalPoolV2ABI, TEventName>,
    "abi" | "address"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    ...config,
  } as UseContractEventConfig<typeof conventionalPoolV2ABI, TEventName>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `eventName` set to `"Banned"`.
 */
export function useConventionalPoolV2BannedEvent(
  config: Omit<
    UseContractEventConfig<typeof conventionalPoolV2ABI, "Banned">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    eventName: "Banned",
    ...config,
  } as UseContractEventConfig<typeof conventionalPoolV2ABI, "Banned">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `eventName` set to `"EmergencyWithdrawalEnabled"`.
 */
export function useConventionalPoolV2EmergencyWithdrawalEnabledEvent(
  config: Omit<
    UseContractEventConfig<
      typeof conventionalPoolV2ABI,
      "EmergencyWithdrawalEnabled"
    >,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    eventName: "EmergencyWithdrawalEnabled",
    ...config,
  } as UseContractEventConfig<typeof conventionalPoolV2ABI, "EmergencyWithdrawalEnabled">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `eventName` set to `"EmergencyWithdrawn"`.
 */
export function useConventionalPoolV2EmergencyWithdrawnEvent(
  config: Omit<
    UseContractEventConfig<typeof conventionalPoolV2ABI, "EmergencyWithdrawn">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    eventName: "EmergencyWithdrawn",
    ...config,
  } as UseContractEventConfig<typeof conventionalPoolV2ABI, "EmergencyWithdrawn">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `eventName` set to `"Exempted"`.
 */
export function useConventionalPoolV2ExemptedEvent(
  config: Omit<
    UseContractEventConfig<typeof conventionalPoolV2ABI, "Exempted">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    eventName: "Exempted",
    ...config,
  } as UseContractEventConfig<typeof conventionalPoolV2ABI, "Exempted">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `eventName` set to `"Harvested"`.
 */
export function useConventionalPoolV2HarvestedEvent(
  config: Omit<
    UseContractEventConfig<typeof conventionalPoolV2ABI, "Harvested">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    eventName: "Harvested",
    ...config,
  } as UseContractEventConfig<typeof conventionalPoolV2ABI, "Harvested">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `eventName` set to `"Migrated"`.
 */
export function useConventionalPoolV2MigratedEvent(
  config: Omit<
    UseContractEventConfig<typeof conventionalPoolV2ABI, "Migrated">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    eventName: "Migrated",
    ...config,
  } as UseContractEventConfig<typeof conventionalPoolV2ABI, "Migrated">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `eventName` set to `"OwnershipTransferred"`.
 */
export function useConventionalPoolV2OwnershipTransferredEvent(
  config: Omit<
    UseContractEventConfig<
      typeof conventionalPoolV2ABI,
      "OwnershipTransferred"
    >,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    eventName: "OwnershipTransferred",
    ...config,
  } as UseContractEventConfig<typeof conventionalPoolV2ABI, "OwnershipTransferred">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `eventName` set to `"Paused"`.
 */
export function useConventionalPoolV2PausedEvent(
  config: Omit<
    UseContractEventConfig<typeof conventionalPoolV2ABI, "Paused">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    eventName: "Paused",
    ...config,
  } as UseContractEventConfig<typeof conventionalPoolV2ABI, "Paused">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `eventName` set to `"Staked"`.
 */
export function useConventionalPoolV2StakedEvent(
  config: Omit<
    UseContractEventConfig<typeof conventionalPoolV2ABI, "Staked">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    eventName: "Staked",
    ...config,
  } as UseContractEventConfig<typeof conventionalPoolV2ABI, "Staked">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalPoolV2ABI}__ and `eventName` set to `"Unstaked"`.
 */
export function useConventionalPoolV2UnstakedEvent(
  config: Omit<
    UseContractEventConfig<typeof conventionalPoolV2ABI, "Unstaked">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalPoolV2ABI,
    address: conventionalPoolV2Address,
    eventName: "Unstaked",
    ...config,
  } as UseContractEventConfig<typeof conventionalPoolV2ABI, "Unstaked">);
}
