import { Dark, Light, Settings } from "@quint/ui/icons";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@quint/ui/menu";
import { useTheme } from "next-themes";
import { useCallback, useEffect, useState } from "react";

const ThemeToggler = () => {
  const { theme, setTheme } = useTheme();
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  const setLightTheme = useCallback(() => {
    setTheme("light");
  }, [setTheme]);
  const setDarkTheme = useCallback(() => {
    setTheme("dark");
  }, [setTheme]);
  const setSystemTheme = useCallback(() => {
    setTheme("system");
  }, [setTheme]);
  if (!mounted)
    return (
      <div className="ml-0 flex h-[48px] w-[48px] origin-[right_center] scale-100 items-center justify-center rounded-full border-transparent bg-gray-500/30 transition-all duration-500 dark:bg-white/60"></div>
    );
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button
          className="ml-0 flex h-[48px] w-[48px] origin-[right_center] scale-100 items-center justify-center rounded-full border-transparent bg-gray-500/30 transition-all duration-500 dark:bg-white/60"
          aria-label="Toggle Dark Mode"
        >
          {theme === "light" ? <Light height={32} width={32} /> : <Dark height={32} width={32} />}
        </button>
        {/* <Button icon variant="text" as="div" aria-label="Open context menu">
        <MoreVertical />
      </Button> */}
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end">
        <DropdownMenuItem
          role="button"
          tabIndex={0}
          className="flex cursor-pointer flex-row gap-2"
          onClick={setSystemTheme}
        >
          <Settings height={30} width={30} />
          System
        </DropdownMenuItem>
        <DropdownMenuItem
          role="button"
          tabIndex={0}
          className="flex cursor-pointer flex-row gap-2"
          onClick={setLightTheme}
        >
          <Light height={32} width={32} />
          <span>Light</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          role="button"
          tabIndex={0}
          className="flex cursor-pointer flex-row gap-2"
          onClick={setDarkTheme}
        >
          <Dark height={32} width={32} />
          <span>Dark</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ThemeToggler;
