import BackgroundImage from "./bg-image";
import Content from "./content";

const Footer = () => {
  return (
    <footer className="relative z-10 overflow-x-hidden bg-default">
      <BackgroundImage />
      <div className="x-padding z-10 bg-transparent py-14">
        <Content />
      </div>
    </footer>
  );
};

export default Footer;
