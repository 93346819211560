import Link from "next/link";
import QuintText from "@quint/assets/logos/quint-text";
import QuintImage from "public/logos/quint-image.png";
import { Image } from "@quint/ui";

interface Props {
  vertical?: boolean;
  width?: number;
  height?: number;
}
const MainLogo = (props: Props) => {
  const { vertical, width = 40 } = props;
  const dir = vertical ? "flex-col" : "flex-row";

  return (
    <Link
      className={` flex items-center justify-center gap-1 ${dir}`}
      href="https://quint.io"
    >
      <h1 className="sr-only">QUINT</h1>
      <Image src={QuintImage} width={width} alt="QUINT" />
      {/* TODO: fix hardcoded IO */}
      <div className="flex flex-row items-center justify-center">
        <QuintText height={width / 1.8} />
        {vertical && <p className="font-medium text-base">.IO</p>}
      </div>
    </Link>
  );
};

export default MainLogo;
