// Generated by @wagmi/cli@0.1.11 on 2023-03-13 at 12:01:05 p.m.
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useContract,
  type UseContractConfig,
  useContractRead,
  type UseContractReadConfig,
  useContractWrite,
  type UseContractWriteConfig,
  usePrepareContractWrite,
  type UsePrepareContractWriteConfig,
  useContractEvent,
  type UseContractEventConfig,
} from "wagmi";
import { type ReadContractResult, type WriteContractMode, type PrepareWriteContractResult } from "wagmi/actions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// LP
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const lpABI = [
  { payable: false, stateMutability: "nonpayable", type: "constructor", inputs: [] },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "owner", internalType: "address", type: "address", indexed: true },
      { name: "spender", internalType: "address", type: "address", indexed: true },
      { name: "value", internalType: "uint256", type: "uint256", indexed: false },
    ],
    name: "Approval",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "sender", internalType: "address", type: "address", indexed: true },
      { name: "amount0", internalType: "uint256", type: "uint256", indexed: false },
      { name: "amount1", internalType: "uint256", type: "uint256", indexed: false },
      { name: "to", internalType: "address", type: "address", indexed: true },
    ],
    name: "Burn",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "sender", internalType: "address", type: "address", indexed: true },
      { name: "amount0", internalType: "uint256", type: "uint256", indexed: false },
      { name: "amount1", internalType: "uint256", type: "uint256", indexed: false },
    ],
    name: "Mint",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "sender", internalType: "address", type: "address", indexed: true },
      { name: "amount0In", internalType: "uint256", type: "uint256", indexed: false },
      { name: "amount1In", internalType: "uint256", type: "uint256", indexed: false },
      { name: "amount0Out", internalType: "uint256", type: "uint256", indexed: false },
      { name: "amount1Out", internalType: "uint256", type: "uint256", indexed: false },
      { name: "to", internalType: "address", type: "address", indexed: true },
    ],
    name: "Swap",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "reserve0", internalType: "uint112", type: "uint112", indexed: false },
      { name: "reserve1", internalType: "uint112", type: "uint112", indexed: false },
    ],
    name: "Sync",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "from", internalType: "address", type: "address", indexed: true },
      { name: "to", internalType: "address", type: "address", indexed: true },
      { name: "value", internalType: "uint256", type: "uint256", indexed: false },
    ],
    name: "Transfer",
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "DOMAIN_SEPARATOR",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "MINIMUM_LIQUIDITY",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "PERMIT_TYPEHASH",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "", internalType: "address", type: "address" },
      { name: "", internalType: "address", type: "address" },
    ],
    name: "allowance",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    constant: false,
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "spender", internalType: "address", type: "address" },
      { name: "value", internalType: "uint256", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "address", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    constant: false,
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "to", internalType: "address", type: "address" }],
    name: "burn",
    outputs: [
      { name: "amount0", internalType: "uint256", type: "uint256" },
      { name: "amount1", internalType: "uint256", type: "uint256" },
    ],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", internalType: "uint8", type: "uint8" }],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "factory",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "getReserves",
    outputs: [
      { name: "_reserve0", internalType: "uint112", type: "uint112" },
      { name: "_reserve1", internalType: "uint112", type: "uint112" },
      { name: "_blockTimestampLast", internalType: "uint32", type: "uint32" },
    ],
  },
  {
    constant: false,
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_token0", internalType: "address", type: "address" },
      { name: "_token1", internalType: "address", type: "address" },
    ],
    name: "initialize",
    outputs: [],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "kLast",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    constant: false,
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "to", internalType: "address", type: "address" }],
    name: "mint",
    outputs: [{ name: "liquidity", internalType: "uint256", type: "uint256" }],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "name",
    outputs: [{ name: "", internalType: "string", type: "string" }],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "address", type: "address" }],
    name: "nonces",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    constant: false,
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "owner", internalType: "address", type: "address" },
      { name: "spender", internalType: "address", type: "address" },
      { name: "value", internalType: "uint256", type: "uint256" },
      { name: "deadline", internalType: "uint256", type: "uint256" },
      { name: "v", internalType: "uint8", type: "uint8" },
      { name: "r", internalType: "bytes32", type: "bytes32" },
      { name: "s", internalType: "bytes32", type: "bytes32" },
    ],
    name: "permit",
    outputs: [],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "price0CumulativeLast",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "price1CumulativeLast",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    constant: false,
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "to", internalType: "address", type: "address" }],
    name: "skim",
    outputs: [],
  },
  {
    constant: false,
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "amount0Out", internalType: "uint256", type: "uint256" },
      { name: "amount1Out", internalType: "uint256", type: "uint256" },
      { name: "to", internalType: "address", type: "address" },
      { name: "data", internalType: "bytes", type: "bytes" },
    ],
    name: "swap",
    outputs: [],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "symbol",
    outputs: [{ name: "", internalType: "string", type: "string" }],
  },
  {
    constant: false,
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "sync",
    outputs: [],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "token0",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "token1",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalSupply",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    constant: false,
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "to", internalType: "address", type: "address" },
      { name: "value", internalType: "uint256", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    constant: false,
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "from", internalType: "address", type: "address" },
      { name: "to", internalType: "address", type: "address" },
      { name: "value", internalType: "uint256", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
] as const;

export const lpAddress = "0x639bf1d9F3a683B28369708596aEfe38F2988e9C" as const;

export const lpConfig = { address: lpAddress, abi: lpABI } as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link lpABI}__.
 */
export function useLp(config: Omit<UseContractConfig, "abi" | "address"> = {} as any) {
  return useContract({ abi: lpABI, address: lpAddress, ...config });
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lpABI}__.
 */
export function useLpRead<TFunctionName extends string, TSelectData = ReadContractResult<typeof lpABI, TFunctionName>>(
  config: Omit<UseContractReadConfig<typeof lpABI, TFunctionName, TSelectData>, "abi" | "address"> = {} as any
) {
  return useContractRead({ abi: lpABI, address: lpAddress, ...config } as UseContractReadConfig<
    typeof lpABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"DOMAIN_SEPARATOR"`.
 */
export function useLpDomainSeparator<TSelectData = ReadContractResult<typeof lpABI, "DOMAIN_SEPARATOR">>(
  config: Omit<
    UseContractReadConfig<typeof lpABI, "DOMAIN_SEPARATOR", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: lpABI,
    address: lpAddress,
    functionName: "DOMAIN_SEPARATOR",
    ...config,
  } as UseContractReadConfig<typeof lpABI, "DOMAIN_SEPARATOR", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"MINIMUM_LIQUIDITY"`.
 */
export function useLpMinimumLiquidity<TSelectData = ReadContractResult<typeof lpABI, "MINIMUM_LIQUIDITY">>(
  config: Omit<
    UseContractReadConfig<typeof lpABI, "MINIMUM_LIQUIDITY", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: lpABI,
    address: lpAddress,
    functionName: "MINIMUM_LIQUIDITY",
    ...config,
  } as UseContractReadConfig<typeof lpABI, "MINIMUM_LIQUIDITY", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"PERMIT_TYPEHASH"`.
 */
export function useLpPermitTypehash<TSelectData = ReadContractResult<typeof lpABI, "PERMIT_TYPEHASH">>(
  config: Omit<
    UseContractReadConfig<typeof lpABI, "PERMIT_TYPEHASH", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: lpABI,
    address: lpAddress,
    functionName: "PERMIT_TYPEHASH",
    ...config,
  } as UseContractReadConfig<typeof lpABI, "PERMIT_TYPEHASH", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"allowance"`.
 */
export function useLpAllowance<TSelectData = ReadContractResult<typeof lpABI, "allowance">>(
  config: Omit<
    UseContractReadConfig<typeof lpABI, "allowance", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: lpABI,
    address: lpAddress,
    functionName: "allowance",
    ...config,
  } as UseContractReadConfig<typeof lpABI, "allowance", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useLpBalanceOf<TSelectData = ReadContractResult<typeof lpABI, "balanceOf">>(
  config: Omit<
    UseContractReadConfig<typeof lpABI, "balanceOf", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: lpABI,
    address: lpAddress,
    functionName: "balanceOf",
    ...config,
  } as UseContractReadConfig<typeof lpABI, "balanceOf", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"decimals"`.
 */
export function useLpDecimals<TSelectData = ReadContractResult<typeof lpABI, "decimals">>(
  config: Omit<
    UseContractReadConfig<typeof lpABI, "decimals", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: lpABI,
    address: lpAddress,
    functionName: "decimals",
    ...config,
  } as UseContractReadConfig<typeof lpABI, "decimals", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"factory"`.
 */
export function useLpFactory<TSelectData = ReadContractResult<typeof lpABI, "factory">>(
  config: Omit<
    UseContractReadConfig<typeof lpABI, "factory", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: lpABI,
    address: lpAddress,
    functionName: "factory",
    ...config,
  } as UseContractReadConfig<typeof lpABI, "factory", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"getReserves"`.
 */
export function useLpGetReserves<TSelectData = ReadContractResult<typeof lpABI, "getReserves">>(
  config: Omit<
    UseContractReadConfig<typeof lpABI, "getReserves", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as never
) {
  return useContractRead({
    abi: lpABI,
    address: lpAddress,
    functionName: "getReserves",
    ...config,
  } as UseContractReadConfig<typeof lpABI, "getReserves", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"kLast"`.
 */
export function useLpKLast<TSelectData = ReadContractResult<typeof lpABI, "kLast">>(
  config: Omit<
    UseContractReadConfig<typeof lpABI, "kLast", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as never
) {
  return useContractRead({ abi: lpABI, address: lpAddress, functionName: "kLast", ...config } as UseContractReadConfig<
    typeof lpABI,
    "kLast",
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"name"`.
 */
export function useLpName<TSelectData = ReadContractResult<typeof lpABI, "name">>(
  config: Omit<
    UseContractReadConfig<typeof lpABI, "name", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as never
) {
  return useContractRead({ abi: lpABI, address: lpAddress, functionName: "name", ...config } as UseContractReadConfig<
    typeof lpABI,
    "name",
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"nonces"`.
 */
export function useLpNonces<TSelectData = ReadContractResult<typeof lpABI, "nonces">>(
  config: Omit<
    UseContractReadConfig<typeof lpABI, "nonces", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as never
) {
  return useContractRead({ abi: lpABI, address: lpAddress, functionName: "nonces", ...config } as UseContractReadConfig<
    typeof lpABI,
    "nonces",
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"price0CumulativeLast"`.
 */
export function useLpPrice0CumulativeLast<TSelectData = ReadContractResult<typeof lpABI, "price0CumulativeLast">>(
  config: Omit<
    UseContractReadConfig<typeof lpABI, "price0CumulativeLast", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: lpABI,
    address: lpAddress,
    functionName: "price0CumulativeLast",
    ...config,
  } as UseContractReadConfig<typeof lpABI, "price0CumulativeLast", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"price1CumulativeLast"`.
 */
export function useLpPrice1CumulativeLast<TSelectData = ReadContractResult<typeof lpABI, "price1CumulativeLast">>(
  config: Omit<
    UseContractReadConfig<typeof lpABI, "price1CumulativeLast", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as never
) {
  return useContractRead({
    abi: lpABI,
    address: lpAddress,
    functionName: "price1CumulativeLast",
    ...config,
  } as UseContractReadConfig<typeof lpABI, "price1CumulativeLast", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"symbol"`.
 */
export function useLpSymbol<TSelectData = ReadContractResult<typeof lpABI, "symbol">>(
  config: Omit<
    UseContractReadConfig<typeof lpABI, "symbol", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as never
) {
  return useContractRead({ abi: lpABI, address: lpAddress, functionName: "symbol", ...config } as UseContractReadConfig<
    typeof lpABI,
    "symbol",
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"token0"`.
 */
export function useLpToken0<TSelectData = ReadContractResult<typeof lpABI, "token0">>(
  config: Omit<
    UseContractReadConfig<typeof lpABI, "token0", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({ abi: lpABI, address: lpAddress, functionName: "token0", ...config } as UseContractReadConfig<
    typeof lpABI,
    "token0",
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"token1"`.
 */
export function useLpToken1<TSelectData = ReadContractResult<typeof lpABI, "token1">>(
  config: Omit<
    UseContractReadConfig<typeof lpABI, "token1", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as never
) {
  return useContractRead({ abi: lpABI, address: lpAddress, functionName: "token1", ...config } as UseContractReadConfig<
    typeof lpABI,
    "token1",
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useLpTotalSupply<TSelectData = ReadContractResult<typeof lpABI, "totalSupply">>(
  config: Omit<
    UseContractReadConfig<typeof lpABI, "totalSupply", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: lpABI,
    address: lpAddress,
    functionName: "totalSupply",
    ...config,
  } as UseContractReadConfig<typeof lpABI, "totalSupply", TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lpABI}__.
 */
export function useLpWrite<TMode extends WriteContractMode, TFunctionName extends string>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lpABI, string>["abi"], TFunctionName>
    : UseContractWriteConfig<TMode, typeof lpABI, TFunctionName> & {
        abi?: never;
      } = {} as any
) {
  return useContractWrite<TMode, typeof lpABI, TFunctionName>({ abi: lpABI, address: lpAddress, ...config } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"approve"`.
 */
export function useLpApprove<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lpABI, "approve">["abi"], "approve"> & {
        functionName?: "approve";
      }
    : UseContractWriteConfig<TMode, typeof lpABI, "approve"> & {
        abi?: never;
        functionName?: "approve";
      } = {} as any
) {
  return useContractWrite<TMode, typeof lpABI, "approve">({
    abi: lpABI,
    address: lpAddress,
    functionName: "approve",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"burn"`.
 */
export function useLpBurn<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lpABI, "burn">["abi"], "burn"> & {
        functionName?: "burn";
      }
    : UseContractWriteConfig<TMode, typeof lpABI, "burn"> & {
        abi?: never;
        functionName?: "burn";
      } = {} as any
) {
  return useContractWrite<TMode, typeof lpABI, "burn">({
    abi: lpABI,
    address: lpAddress,
    functionName: "burn",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"initialize"`.
 */
export function useLpInitialize<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lpABI, "initialize">["abi"], "initialize"> & {
        functionName?: "initialize";
      }
    : UseContractWriteConfig<TMode, typeof lpABI, "initialize"> & {
        abi?: never;
        functionName?: "initialize";
      } = {} as any
) {
  return useContractWrite<TMode, typeof lpABI, "initialize">({
    abi: lpABI,
    address: lpAddress,
    functionName: "initialize",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"mint"`.
 */
export function useLpMint<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lpABI, "mint">["abi"], "mint"> & {
        functionName?: "mint";
      }
    : UseContractWriteConfig<TMode, typeof lpABI, "mint"> & {
        abi?: never;
        functionName?: "mint";
      } = {} as any
) {
  return useContractWrite<TMode, typeof lpABI, "mint">({
    abi: lpABI,
    address: lpAddress,
    functionName: "mint",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"permit"`.
 */
export function useLpPermit<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lpABI, "permit">["abi"], "permit"> & {
        functionName?: "permit";
      }
    : UseContractWriteConfig<TMode, typeof lpABI, "permit"> & {
        abi?: never;
        functionName?: "permit";
      } = {} as any
) {
  return useContractWrite<TMode, typeof lpABI, "permit">({
    abi: lpABI,
    address: lpAddress,
    functionName: "permit",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"skim"`.
 */
export function useLpSkim<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lpABI, "skim">["abi"], "skim"> & {
        functionName?: "skim";
      }
    : UseContractWriteConfig<TMode, typeof lpABI, "skim"> & {
        abi?: never;
        functionName?: "skim";
      } = {} as any
) {
  return useContractWrite<TMode, typeof lpABI, "skim">({
    abi: lpABI,
    address: lpAddress,
    functionName: "skim",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"swap"`.
 */
export function useLpSwap<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lpABI, "swap">["abi"], "swap"> & {
        functionName?: "swap";
      }
    : UseContractWriteConfig<TMode, typeof lpABI, "swap"> & {
        abi?: never;
        functionName?: "swap";
      } = {} as any
) {
  return useContractWrite<TMode, typeof lpABI, "swap">({
    abi: lpABI,
    address: lpAddress,
    functionName: "swap",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"sync"`.
 */
export function useLpSync<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lpABI, "sync">["abi"], "sync"> & {
        functionName?: "sync";
      }
    : UseContractWriteConfig<TMode, typeof lpABI, "sync"> & {
        abi?: never;
        functionName?: "sync";
      } = {} as any
) {
  return useContractWrite<TMode, typeof lpABI, "sync">({
    abi: lpABI,
    address: lpAddress,
    functionName: "sync",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"transfer"`.
 */
export function useLpTransfer<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lpABI, "transfer">["abi"], "transfer"> & {
        functionName?: "transfer";
      }
    : UseContractWriteConfig<TMode, typeof lpABI, "transfer"> & {
        abi?: never;
        functionName?: "transfer";
      } = {} as any
) {
  return useContractWrite<TMode, typeof lpABI, "transfer">({
    abi: lpABI,
    address: lpAddress,
    functionName: "transfer",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useLpTransferFrom<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lpABI, "transferFrom">["abi"], "transferFrom"> & {
        functionName?: "transferFrom";
      }
    : UseContractWriteConfig<TMode, typeof lpABI, "transferFrom"> & {
        abi?: never;
        functionName?: "transferFrom";
      } = {} as any
) {
  return useContractWrite<TMode, typeof lpABI, "transferFrom">({
    abi: lpABI,
    address: lpAddress,
    functionName: "transferFrom",
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lpABI}__.
 */
export function usePrepareLpWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof lpABI, TFunctionName>, "abi" | "address"> = {} as any
) {
  return usePrepareContractWrite({ abi: lpABI, address: lpAddress, ...config } as UsePrepareContractWriteConfig<
    typeof lpABI,
    TFunctionName
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareLpApprove(
  config: Omit<UsePrepareContractWriteConfig<typeof lpABI, "approve">, "abi" | "address" | "functionName"> = {} as any
) {
  return usePrepareContractWrite({
    abi: lpABI,
    address: lpAddress,
    functionName: "approve",
    ...config,
  } as UsePrepareContractWriteConfig<typeof lpABI, "approve">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"burn"`.
 */
export function usePrepareLpBurn(
  config: Omit<UsePrepareContractWriteConfig<typeof lpABI, "burn">, "abi" | "address" | "functionName"> = {} as any
) {
  return usePrepareContractWrite({
    abi: lpABI,
    address: lpAddress,
    functionName: "burn",
    ...config,
  } as UsePrepareContractWriteConfig<typeof lpABI, "burn">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"initialize"`.
 */
export function usePrepareLpInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lpABI, "initialize">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: lpABI,
    address: lpAddress,
    functionName: "initialize",
    ...config,
  } as UsePrepareContractWriteConfig<typeof lpABI, "initialize">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"mint"`.
 */
export function usePrepareLpMint(
  config: Omit<UsePrepareContractWriteConfig<typeof lpABI, "mint">, "abi" | "address" | "functionName"> = {} as any
) {
  return usePrepareContractWrite({
    abi: lpABI,
    address: lpAddress,
    functionName: "mint",
    ...config,
  } as UsePrepareContractWriteConfig<typeof lpABI, "mint">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"permit"`.
 */
export function usePrepareLpPermit(
  config: Omit<UsePrepareContractWriteConfig<typeof lpABI, "permit">, "abi" | "address" | "functionName"> = {} as any
) {
  return usePrepareContractWrite({
    abi: lpABI,
    address: lpAddress,
    functionName: "permit",
    ...config,
  } as UsePrepareContractWriteConfig<typeof lpABI, "permit">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"skim"`.
 */
export function usePrepareLpSkim(
  config: Omit<UsePrepareContractWriteConfig<typeof lpABI, "skim">, "abi" | "address" | "functionName"> = {} as any
) {
  return usePrepareContractWrite({
    abi: lpABI,
    address: lpAddress,
    functionName: "skim",
    ...config,
  } as UsePrepareContractWriteConfig<typeof lpABI, "skim">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"swap"`.
 */
export function usePrepareLpSwap(
  config: Omit<UsePrepareContractWriteConfig<typeof lpABI, "swap">, "abi" | "address" | "functionName"> = {} as any
) {
  return usePrepareContractWrite({
    abi: lpABI,
    address: lpAddress,
    functionName: "swap",
    ...config,
  } as UsePrepareContractWriteConfig<typeof lpABI, "swap">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"sync"`.
 */
export function usePrepareLpSync(
  config: Omit<UsePrepareContractWriteConfig<typeof lpABI, "sync">, "abi" | "address" | "functionName"> = {} as any
) {
  return usePrepareContractWrite({
    abi: lpABI,
    address: lpAddress,
    functionName: "sync",
    ...config,
  } as UsePrepareContractWriteConfig<typeof lpABI, "sync">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareLpTransfer(
  config: Omit<UsePrepareContractWriteConfig<typeof lpABI, "transfer">, "abi" | "address" | "functionName"> = {} as any
) {
  return usePrepareContractWrite({
    abi: lpABI,
    address: lpAddress,
    functionName: "transfer",
    ...config,
  } as UsePrepareContractWriteConfig<typeof lpABI, "transfer">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lpABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareLpTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lpABI, "transferFrom">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: lpABI,
    address: lpAddress,
    functionName: "transferFrom",
    ...config,
  } as UsePrepareContractWriteConfig<typeof lpABI, "transferFrom">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link lpABI}__.
 */
export function useLpEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof lpABI, TEventName>, "abi" | "address"> = {} as any
) {
  return useContractEvent({ abi: lpABI, address: lpAddress, ...config } as UseContractEventConfig<
    typeof lpABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link lpABI}__ and `eventName` set to `"Approval"`.
 */
export function useLpApprovalEvent(
  config: Omit<UseContractEventConfig<typeof lpABI, "Approval">, "abi" | "address" | "eventName"> = {} as any
) {
  return useContractEvent({
    abi: lpABI,
    address: lpAddress,
    eventName: "Approval",
    ...config,
  } as UseContractEventConfig<typeof lpABI, "Approval">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link lpABI}__ and `eventName` set to `"Burn"`.
 */
export function useLpBurnEvent(
  config: Omit<UseContractEventConfig<typeof lpABI, "Burn">, "abi" | "address" | "eventName"> = {} as any
) {
  return useContractEvent({ abi: lpABI, address: lpAddress, eventName: "Burn", ...config } as UseContractEventConfig<
    typeof lpABI,
    "Burn"
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link lpABI}__ and `eventName` set to `"Mint"`.
 */
export function useLpMintEvent(
  config: Omit<UseContractEventConfig<typeof lpABI, "Mint">, "abi" | "address" | "eventName"> = {} as any
) {
  return useContractEvent({ abi: lpABI, address: lpAddress, eventName: "Mint", ...config } as UseContractEventConfig<
    typeof lpABI,
    "Mint"
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link lpABI}__ and `eventName` set to `"Swap"`.
 */
export function useLpSwapEvent(
  config: Omit<UseContractEventConfig<typeof lpABI, "Swap">, "abi" | "address" | "eventName"> = {} as any
) {
  return useContractEvent({ abi: lpABI, address: lpAddress, eventName: "Swap", ...config } as UseContractEventConfig<
    typeof lpABI,
    "Swap"
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link lpABI}__ and `eventName` set to `"Sync"`.
 */
export function useLpSyncEvent(
  config: Omit<UseContractEventConfig<typeof lpABI, "Sync">, "abi" | "address" | "eventName"> = {} as any
) {
  return useContractEvent({ abi: lpABI, address: lpAddress, eventName: "Sync", ...config } as UseContractEventConfig<
    typeof lpABI,
    "Sync"
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link lpABI}__ and `eventName` set to `"Transfer"`.
 */
export function useLpTransferEvent(
  config: Omit<UseContractEventConfig<typeof lpABI, "Transfer">, "abi" | "address" | "eventName"> = {} as any
) {
  return useContractEvent({
    abi: lpABI,
    address: lpAddress,
    eventName: "Transfer",
    ...config,
  } as UseContractEventConfig<typeof lpABI, "Transfer">);
}
