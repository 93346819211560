import MainLogo from "../main-logo";
import Links from "./links";
import SocialRow from "./social-row";

const Content = () => {
  return (
    <div className="z-20 flex h-full w-full flex-1 flex-col gap-20">
      <div className="flex flex-1 flex-col gap-10 md:flex-row md:gap-28">
        <div className="flex w-full flex-col items-start gap-4 md:w-[40%]">
          <MainLogo />
          <p className="font-hanken leading-5 text-sm">
            Quint is the global token bringing real-world rewards, use-cases and utilities to users by bridging crypto
            and DeFi with the real world. Revolutionary super-staking pools deliver returns while unlocking exclusive
            perks. Backed by real-world veterans and business titans, Quint is paving the way to mainstream crypto
            adoption.
          </p>
        </div>

        <Links />
      </div>
      <div className="flex flex-col gap-4">
        <hr className="opacity-20" />
        <div className="flex flex-col-reverse items-center justify-center gap-4 md:flex-row md:items-center md:justify-between">
          <p className="font-satoshi text-offset text-sm">©2022 QUINT. All rights reserved.</p>
          <SocialRow />
        </div>
      </div>
    </div>
  );
};

export default Content;
