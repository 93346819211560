import { type ReactNode } from "react";
import { cn } from "./utils";

export type BadgeProps = {
  content: string;
  children: ReactNode;
  className?: string;
};

export const Badge = ({ children, content, className }: BadgeProps) => {
  return (
    <div className={cn("relative", className)}>
      <div
        className={cn(
          "absolute top-0 right-0 flex h-[18px] min-w-[18px] translate-y-[-50%] translate-x-[-9px] items-center justify-center rounded-full bg-[#F1FAFD] px-1 dark:bg-white"
        )}
      >
        <p className="mt-[-1px] text-[12px] dark:text-black">{content}</p>
      </div>
      {children}
    </div>
  );
};
