import Link from "next/link";

const applications = [
  {
    name: "Super staking platform",
    url: "https://stake.quint.io/",
  },
  {
    name: "Boutique NFT marketplace",
    url: "https://nft.quint.io/",
  },
  {
    name: "Quint Swap",
    url: "https://swap.quint.io/",
  },
];
const testmonials = [
  {
    name: "Blog",
    url: "https://blog.quint.io/",
  },
  {
    name: "Twitter",
    url: "https://twitter.com/projectquint",
  },
  {
    name: "Telegram",
    url: "http://t.me/projectquint",
  },
];
const about = [
  {
    name: "Whitepaper",
    url: "https://whitepaper.quint.io/quint-whitepaper-1/",
  },
  {
    name: "FAQ",
    url: "https://quint.io/#faq",
  },
];

const Links = () => {
  return (
    <div className="flex flex-wrap gap-10">
      <div className="flex flex-col gap-3">
        <p className="font-hanken font-semibold text-base">Applications</p>
        {applications.map((item) => (
          <Link href={item.url} key={item.name} target="_blank">
            <p key={item.name} className="font-satoshi text-sm">
              {item.name}
            </p>
          </Link>
        ))}
      </div>

      <div className="flex flex-col gap-3">
        <p className="font-hanken font-semibold text-base">Testimonials</p>
        {testmonials.map((item) => (
          <Link href={item.url} key={item.name} target="_blank">
            <p className="font-satoshi text-sm">{item.name}</p>
          </Link>
        ))}
      </div>

      <div className="flex flex-col gap-3">
        <p className="font-hanken font-semibold text-base">About</p>
        {about.map((item) => (
          <Link href={item.url} key={item.name} target="_blank">
            <p key={item.name} className="font-satoshi text-sm">
              {item.name}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Links;
