// Generated by @wagmi/cli@0.1.11 on 2023-03-13 at 12:01:06 p.m.
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useContract,
  type UseContractConfig,
  useContractRead,
  type UseContractReadConfig,
  useContractWrite,
  type UseContractWriteConfig,
  usePrepareContractWrite,
  type UsePrepareContractWriteConfig,
  useContractEvent,
  type UseContractEventConfig,
} from "wagmi";
import {
  type ReadContractResult,
  type WriteContractMode,
  type PrepareWriteContractResult,
} from "wagmi/actions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// LuxuryPoolB
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const luxuryPoolBABI = [
  {
    stateMutability: "nonpayable",
    type: "constructor",
    inputs: [
      { name: "_owner", internalType: "address payable", type: "address" },
      {
        name: "_distributor",
        internalType: "address payable",
        type: "address",
      },
    ],
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "DEPOSITr",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "DEPOSIT",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "previousOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "newOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "OwnershipTransferred",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "DEPOSITr",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "WITHDRAW",
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      {
        name: "_distributor",
        internalType: "address payable",
        type: "address",
      },
    ],
    name: "ChangeDistributor",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_t1", internalType: "uint256", type: "uint256" },
      { name: "_t2", internalType: "uint256", type: "uint256" },
    ],
    name: "SetDurations",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_token", internalType: "uint256", type: "uint256" }],
    name: "SetMinAmount",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_token", internalType: "uint256", type: "uint256" },
      { name: "_divider", internalType: "uint256", type: "uint256" },
    ],
    name: "SetPoolsReward",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_tokens", internalType: "uint256", type: "uint256" }],
    name: "SetTicketsCount",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_amount", internalType: "uint256", type: "uint256" }],
    name: "calculateTokenReward",
    outputs: [{ name: "_reward", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_amount", internalType: "uint256", type: "uint256" }],
    name: "deposit",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "depositDuration",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "distributor",
    outputs: [{ name: "", internalType: "address payable", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "_user", internalType: "address", type: "address" },
      { name: "_index", internalType: "uint256", type: "uint256" },
    ],
    name: "getUserDepositInfo",
    outputs: [
      { name: "_amount", internalType: "uint256", type: "uint256" },
      { name: "_reward", internalType: "uint256", type: "uint256" },
      { name: "_tickets", internalType: "uint256", type: "uint256" },
      { name: "_time", internalType: "uint256", type: "uint256" },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_user", internalType: "address", type: "address" }],
    name: "getUserInfo",
    outputs: [
      { name: "_isExists", internalType: "bool", type: "bool" },
      { name: "_totalDeposit", internalType: "uint256", type: "uint256" },
      { name: "_totalTickets", internalType: "uint256", type: "uint256" },
      { name: "_totalReward", internalType: "uint256", type: "uint256" },
      { name: "_totalWithdrawn", internalType: "uint256", type: "uint256" },
      { name: "_isWithdrawn", internalType: "bool", type: "bool" },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_user", internalType: "address", type: "address" }],
    name: "getUserTotalDeposits",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "isPoolStart",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "minToken",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "owner",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "poolCloseTime",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "poolDuration",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "poolEndTime",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "poolStartTime",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "rewardDivider",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "startPool",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "ticketDivider",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "token",
    outputs: [{ name: "", internalType: "contract IERC20", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "tokenReward",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalDeposit",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalTickets",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalWithdrawn",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "newOwner", internalType: "address", type: "address" }],
    name: "transferOwnership",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "uniqueUsers",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "withdraw",
    outputs: [],
  },
] as const;

export const luxuryPoolBAddress =
  "0xc12f67Bb1A1e7fb6e8ee0bDAD033080aF06c3650" as const;

export const luxuryPoolBConfig = {
  address: luxuryPoolBAddress,
  abi: luxuryPoolBABI,
} as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link luxuryPoolBABI}__.
 */
export function useLuxuryPoolB(
  config: Omit<UseContractConfig, "abi" | "address"> = {} as any
) {
  return useContract({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    ...config,
  });
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__.
 */
export function useLuxuryPoolBRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolBABI, TFunctionName, TSelectData>,
    "abi" | "address"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"calculateTokenReward"`.
 */
export function useLuxuryPoolBCalculateTokenReward<
  TSelectData = ReadContractResult<
    typeof luxuryPoolBABI,
    "calculateTokenReward"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof luxuryPoolBABI,
      "calculateTokenReward",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "calculateTokenReward",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "calculateTokenReward", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"depositDuration"`.
 */
export function useLuxuryPoolBDepositDuration<
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, "depositDuration">
>(
  config: Omit<
    UseContractReadConfig<
      typeof luxuryPoolBABI,
      "depositDuration",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "depositDuration",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "depositDuration", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"distributor"`.
 */
export function useLuxuryPoolBDistributor<
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, "distributor">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolBABI, "distributor", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "distributor",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "distributor", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"getUserDepositInfo"`.
 */
export function useLuxuryPoolBGetUserDepositInfo<
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, "getUserDepositInfo">
>(
  config: Omit<
    UseContractReadConfig<
      typeof luxuryPoolBABI,
      "getUserDepositInfo",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "getUserDepositInfo",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "getUserDepositInfo", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"getUserInfo"`.
 */
export function useLuxuryPoolBGetUserInfo<
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, "getUserInfo">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolBABI, "getUserInfo", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "getUserInfo",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "getUserInfo", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"getUserTotalDeposits"`.
 */
export function useLuxuryPoolBGetUserTotalDeposits<
  TSelectData = ReadContractResult<
    typeof luxuryPoolBABI,
    "getUserTotalDeposits"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof luxuryPoolBABI,
      "getUserTotalDeposits",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "getUserTotalDeposits",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "getUserTotalDeposits", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"isPoolStart"`.
 */
export function useLuxuryPoolBIsPoolStart<
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, "isPoolStart">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolBABI, "isPoolStart", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "isPoolStart",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "isPoolStart", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"minToken"`.
 */
export function useLuxuryPoolBMinToken<
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, "minToken">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolBABI, "minToken", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "minToken",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "minToken", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"owner"`.
 */
export function useLuxuryPoolBOwner<
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, "owner">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolBABI, "owner", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "owner",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "owner", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"poolCloseTime"`.
 */
export function useLuxuryPoolBPoolCloseTime<
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, "poolCloseTime">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolBABI, "poolCloseTime", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "poolCloseTime",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "poolCloseTime", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"poolDuration"`.
 */
export function useLuxuryPoolBPoolDuration<
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, "poolDuration">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolBABI, "poolDuration", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "poolDuration",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "poolDuration", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"poolEndTime"`.
 */
export function useLuxuryPoolBPoolEndTime<
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, "poolEndTime">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolBABI, "poolEndTime", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "poolEndTime",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "poolEndTime", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"poolStartTime"`.
 */
export function useLuxuryPoolBPoolStartTime<
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, "poolStartTime">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolBABI, "poolStartTime", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "poolStartTime",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "poolStartTime", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"rewardDivider"`.
 */
export function useLuxuryPoolBRewardDivider<
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, "rewardDivider">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolBABI, "rewardDivider", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "rewardDivider",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "rewardDivider", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"ticketDivider"`.
 */
export function useLuxuryPoolBTicketDivider<
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, "ticketDivider">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolBABI, "ticketDivider", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "ticketDivider",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "ticketDivider", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"token"`.
 */
export function useLuxuryPoolBToken<
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, "token">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolBABI, "token", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "token",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "token", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"tokenReward"`.
 */
export function useLuxuryPoolBTokenReward<
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, "tokenReward">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolBABI, "tokenReward", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "tokenReward",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "tokenReward", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"totalDeposit"`.
 */
export function useLuxuryPoolBTotalDeposit<
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, "totalDeposit">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolBABI, "totalDeposit", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "totalDeposit",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "totalDeposit", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"totalTickets"`.
 */
export function useLuxuryPoolBTotalTickets<
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, "totalTickets">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolBABI, "totalTickets", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "totalTickets",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "totalTickets", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"totalWithdrawn"`.
 */
export function useLuxuryPoolBTotalWithdrawn<
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, "totalWithdrawn">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolBABI, "totalWithdrawn", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "totalWithdrawn",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "totalWithdrawn", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"uniqueUsers"`.
 */
export function useLuxuryPoolBUniqueUsers<
  TSelectData = ReadContractResult<typeof luxuryPoolBABI, "uniqueUsers">
>(
  config: Omit<
    UseContractReadConfig<typeof luxuryPoolBABI, "uniqueUsers", TSelectData>,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "uniqueUsers",
    ...config,
  } as UseContractReadConfig<typeof luxuryPoolBABI, "uniqueUsers", TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__.
 */
export function useLuxuryPoolBWrite<
  TMode extends WriteContractMode,
  TFunctionName extends string
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof luxuryPoolBABI, string>["abi"],
        TFunctionName
      >
    : UseContractWriteConfig<TMode, typeof luxuryPoolBABI, TFunctionName> & {
        abi?: never;
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolBABI, TFunctionName>({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"ChangeDistributor"`.
 */
export function useLuxuryPoolBChangeDistributor<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof luxuryPoolBABI,
          "ChangeDistributor"
        >["abi"],
        "ChangeDistributor"
      > & { functionName?: "ChangeDistributor" }
    : UseContractWriteConfig<
        TMode,
        typeof luxuryPoolBABI,
        "ChangeDistributor"
      > & {
        abi?: never;
        functionName?: "ChangeDistributor";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolBABI, "ChangeDistributor">({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "ChangeDistributor",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"SetDurations"`.
 */
export function useLuxuryPoolBSetDurations<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof luxuryPoolBABI,
          "SetDurations"
        >["abi"],
        "SetDurations"
      > & { functionName?: "SetDurations" }
    : UseContractWriteConfig<TMode, typeof luxuryPoolBABI, "SetDurations"> & {
        abi?: never;
        functionName?: "SetDurations";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolBABI, "SetDurations">({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "SetDurations",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"SetMinAmount"`.
 */
export function useLuxuryPoolBSetMinAmount<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof luxuryPoolBABI,
          "SetMinAmount"
        >["abi"],
        "SetMinAmount"
      > & { functionName?: "SetMinAmount" }
    : UseContractWriteConfig<TMode, typeof luxuryPoolBABI, "SetMinAmount"> & {
        abi?: never;
        functionName?: "SetMinAmount";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolBABI, "SetMinAmount">({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "SetMinAmount",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"SetPoolsReward"`.
 */
export function useLuxuryPoolBSetPoolsReward<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof luxuryPoolBABI,
          "SetPoolsReward"
        >["abi"],
        "SetPoolsReward"
      > & { functionName?: "SetPoolsReward" }
    : UseContractWriteConfig<TMode, typeof luxuryPoolBABI, "SetPoolsReward"> & {
        abi?: never;
        functionName?: "SetPoolsReward";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolBABI, "SetPoolsReward">({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "SetPoolsReward",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"SetTicketsCount"`.
 */
export function useLuxuryPoolBSetTicketsCount<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof luxuryPoolBABI,
          "SetTicketsCount"
        >["abi"],
        "SetTicketsCount"
      > & { functionName?: "SetTicketsCount" }
    : UseContractWriteConfig<
        TMode,
        typeof luxuryPoolBABI,
        "SetTicketsCount"
      > & {
        abi?: never;
        functionName?: "SetTicketsCount";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolBABI, "SetTicketsCount">({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "SetTicketsCount",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"deposit"`.
 */
export function useLuxuryPoolBDeposit<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof luxuryPoolBABI, "deposit">["abi"],
        "deposit"
      > & { functionName?: "deposit" }
    : UseContractWriteConfig<TMode, typeof luxuryPoolBABI, "deposit"> & {
        abi?: never;
        functionName?: "deposit";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolBABI, "deposit">({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "deposit",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useLuxuryPoolBRenounceOwnership<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof luxuryPoolBABI,
          "renounceOwnership"
        >["abi"],
        "renounceOwnership"
      > & { functionName?: "renounceOwnership" }
    : UseContractWriteConfig<
        TMode,
        typeof luxuryPoolBABI,
        "renounceOwnership"
      > & {
        abi?: never;
        functionName?: "renounceOwnership";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolBABI, "renounceOwnership">({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "renounceOwnership",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"startPool"`.
 */
export function useLuxuryPoolBStartPool<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof luxuryPoolBABI, "startPool">["abi"],
        "startPool"
      > & { functionName?: "startPool" }
    : UseContractWriteConfig<TMode, typeof luxuryPoolBABI, "startPool"> & {
        abi?: never;
        functionName?: "startPool";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolBABI, "startPool">({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "startPool",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useLuxuryPoolBTransferOwnership<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof luxuryPoolBABI,
          "transferOwnership"
        >["abi"],
        "transferOwnership"
      > & { functionName?: "transferOwnership" }
    : UseContractWriteConfig<
        TMode,
        typeof luxuryPoolBABI,
        "transferOwnership"
      > & {
        abi?: never;
        functionName?: "transferOwnership";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolBABI, "transferOwnership">({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "transferOwnership",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"withdraw"`.
 */
export function useLuxuryPoolBWithdraw<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof luxuryPoolBABI, "withdraw">["abi"],
        "withdraw"
      > & { functionName?: "withdraw" }
    : UseContractWriteConfig<TMode, typeof luxuryPoolBABI, "withdraw"> & {
        abi?: never;
        functionName?: "withdraw";
      } = {} as any
) {
  return useContractWrite<TMode, typeof luxuryPoolBABI, "withdraw">({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "withdraw",
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__.
 */
export function usePrepareLuxuryPoolBWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolBABI, TFunctionName>,
    "abi" | "address"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolBABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"ChangeDistributor"`.
 */
export function usePrepareLuxuryPoolBChangeDistributor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "ChangeDistributor">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "ChangeDistributor",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "ChangeDistributor">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"SetDurations"`.
 */
export function usePrepareLuxuryPoolBSetDurations(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "SetDurations">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "SetDurations",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "SetDurations">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"SetMinAmount"`.
 */
export function usePrepareLuxuryPoolBSetMinAmount(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "SetMinAmount">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "SetMinAmount",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "SetMinAmount">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"SetPoolsReward"`.
 */
export function usePrepareLuxuryPoolBSetPoolsReward(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "SetPoolsReward">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "SetPoolsReward",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "SetPoolsReward">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"SetTicketsCount"`.
 */
export function usePrepareLuxuryPoolBSetTicketsCount(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "SetTicketsCount">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "SetTicketsCount",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "SetTicketsCount">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"deposit"`.
 */
export function usePrepareLuxuryPoolBDeposit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "deposit">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "deposit",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "deposit">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareLuxuryPoolBRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "renounceOwnership">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "renounceOwnership",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "renounceOwnership">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"startPool"`.
 */
export function usePrepareLuxuryPoolBStartPool(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "startPool">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "startPool",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "startPool">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareLuxuryPoolBTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "transferOwnership">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "transferOwnership",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "transferOwnership">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link luxuryPoolBABI}__ and `functionName` set to `"withdraw"`.
 */
export function usePrepareLuxuryPoolBWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "withdraw">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    functionName: "withdraw",
    ...config,
  } as UsePrepareContractWriteConfig<typeof luxuryPoolBABI, "withdraw">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link luxuryPoolBABI}__.
 */
export function useLuxuryPoolBEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof luxuryPoolBABI, TEventName>,
    "abi" | "address"
  > = {} as any
) {
  return useContractEvent({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    ...config,
  } as UseContractEventConfig<typeof luxuryPoolBABI, TEventName>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link luxuryPoolBABI}__ and `eventName` set to `"DEPOSIT"`.
 */
export function useLuxuryPoolBDepositEvent(
  config: Omit<
    UseContractEventConfig<typeof luxuryPoolBABI, "DEPOSIT">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    eventName: "DEPOSIT",
    ...config,
  } as UseContractEventConfig<typeof luxuryPoolBABI, "DEPOSIT">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link luxuryPoolBABI}__ and `eventName` set to `"OwnershipTransferred"`.
 */
export function useLuxuryPoolBOwnershipTransferredEvent(
  config: Omit<
    UseContractEventConfig<typeof luxuryPoolBABI, "OwnershipTransferred">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    eventName: "OwnershipTransferred",
    ...config,
  } as UseContractEventConfig<typeof luxuryPoolBABI, "OwnershipTransferred">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link luxuryPoolBABI}__ and `eventName` set to `"WITHDRAW"`.
 */
export function useLuxuryPoolBWithdrawEvent(
  config: Omit<
    UseContractEventConfig<typeof luxuryPoolBABI, "WITHDRAW">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: luxuryPoolBABI,
    address: luxuryPoolBAddress,
    eventName: "WITHDRAW",
    ...config,
  } as UseContractEventConfig<typeof luxuryPoolBABI, "WITHDRAW">);
}
