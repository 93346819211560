// Generated by @wagmi/cli@0.1.11 on 2023-03-13 at 12:01:05 p.m.
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useContract,
  type UseContractConfig,
  useContractRead,
  type UseContractReadConfig,
  useContractWrite,
  type UseContractWriteConfig,
  usePrepareContractWrite,
  type UsePrepareContractWriteConfig,
  useContractEvent,
  type UseContractEventConfig,
} from "wagmi";
import {
  type ReadContractResult,
  type WriteContractMode,
  type PrepareWriteContractResult,
} from "wagmi/actions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ConventionalBNBPoolV2
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const conventionalBnbPoolV2ABI = [
  {
    stateMutability: "nonpayable",
    type: "constructor",
    inputs: [
      {
        name: "initialOwner",
        internalType: "address payable",
        type: "address",
      },
      { name: "rewardToken", internalType: "address", type: "address" },
      { name: "liquidityPair", internalType: "address", type: "address" },
      { name: "distributor", internalType: "address payable", type: "address" },
      { name: "legacyPool", internalType: "address", type: "address" },
    ],
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "staker",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      { name: "isBanned", internalType: "bool", type: "bool", indexed: false },
    ],
    name: "Banned",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "isEnabled", internalType: "bool", type: "bool", indexed: false },
    ],
    name: "EmergencyWithdrawalEnabled",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "staker",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "lpAmount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "tokenAmount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "stakedAt",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "rewardedAt",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "EmergencyWithdrawn",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "staker",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      { name: "isExempt", internalType: "bool", type: "bool", indexed: false },
    ],
    name: "Exempted",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "staker",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "rewards",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "Harvested",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "staker",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: true,
      },
    ],
    name: "Migrated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "previousOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "newOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "OwnershipTransferred",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "isPaused", internalType: "bool", type: "bool", indexed: false },
    ],
    name: "Paused",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "staker",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "Staked",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "staker",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "Unstaked",
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "LIQUIDITY_PAIR",
    outputs: [{ name: "", internalType: "contract IPair", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "QUINT_TOKEN",
    outputs: [{ name: "", internalType: "contract IERC20", type: "address" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "account", internalType: "address", type: "address" },
      { name: "isExempt", internalType: "bool", type: "bool" },
    ],
    name: "SetExemptFromTaxation",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      {
        name: "newMinimumStakingAmount",
        internalType: "uint256",
        type: "uint256",
      },
    ],
    name: "SetMinimumStakingAmount",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "newRewardMultiplier", internalType: "uint256", type: "uint256" },
      { name: "newRewardDivider", internalType: "uint256", type: "uint256" },
    ],
    name: "SetPoolAPYSettings",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      {
        name: "newUnstakeTaxPercentage",
        internalType: "uint256",
        type: "uint256",
      },
      {
        name: "newUnstakeTaxRemovedAt",
        internalType: "uint256",
        type: "uint256",
      },
    ],
    name: "SetUnstakeTaxAndDuration",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "address", type: "address" }],
    name: "accountDetails",
    outputs: [
      { name: "totalStakedLPs", internalType: "uint256", type: "uint256" },
      { name: "totalUnstakedLPs", internalType: "uint256", type: "uint256" },
      { name: "totalStakeEntries", internalType: "uint256", type: "uint256" },
      {
        name: "totalHarvestedRewards",
        internalType: "uint256",
        type: "uint256",
      },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "account", internalType: "address", type: "address" }],
    name: "calculateLPReward",
    outputs: [{ name: "reward", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "emergencyWithdraw",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "LPAmount", internalType: "uint256", type: "uint256" }],
    name: "getTokenForLP",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "getTokenReserve",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "harvest",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "address", type: "address" }],
    name: "isBanned",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "isEmergencyWithdrawEnabled",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "address", type: "address" }],
    name: "isExemptFromTaxation",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "address", type: "address" }],
    name: "isMigrated",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "isPaused",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "legacyStakingPool",
    outputs: [
      {
        name: "",
        internalType: "contract IQuintConventionalPool",
        type: "address",
      },
    ],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "migrate",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "minimumStakingAmount",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "owner",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "rewardDivider",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "rewardMultiplier",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "rewardVault",
    outputs: [{ name: "", internalType: "address payable", type: "address" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "state", internalType: "bool", type: "bool" }],
    name: "setAllowEmergencyWithdraw",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "account", internalType: "address", type: "address" },
      { name: "state", internalType: "bool", type: "bool" },
    ],
    name: "setBanState",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "state", internalType: "bool", type: "bool" }],
    name: "setPoolPauseState",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      {
        name: "newRewardVault",
        internalType: "address payable",
        type: "address",
      },
    ],
    name: "setRewardVault",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "amount", internalType: "uint256", type: "uint256" }],
    name: "stake",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "address", type: "address" }],
    name: "stakingDetails",
    outputs: [
      { name: "stakedAt", internalType: "uint256", type: "uint256" },
      { name: "LPAmount", internalType: "uint256", type: "uint256" },
      { name: "tokenAmount", internalType: "uint256", type: "uint256" },
      { name: "rewardedAt", internalType: "uint256", type: "uint256" },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalHarvestedRewards",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalStakedLPs",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalUniqueStakers",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalUnstakedLPs",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "newOwner", internalType: "address", type: "address" }],
    name: "transferOwnership",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "unstake",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "amount", internalType: "uint256", type: "uint256" }],
    name: "unstakePartial",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "unstakeTaxPercentage",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "unstakeTaxRemovedAt",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
] as const;

export const conventionalBnbPoolV2Address =
  "0xFE9c735d8f498D7B6457a5DD546A6B5aFe0fd935" as const;

export const conventionalBnbPoolV2Config = {
  address: conventionalBnbPoolV2Address,
  abi: conventionalBnbPoolV2ABI,
} as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__.
 */
export function useConventionalBnbPoolV2(
  config: Omit<UseContractConfig, "abi" | "address"> = {} as any
) {
  return useContract({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    ...config,
  });
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__.
 */
export function useConventionalBnbPoolV2Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    TFunctionName
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      TFunctionName,
      TSelectData
    >,
    "abi" | "address"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"LIQUIDITY_PAIR"`.
 */
export function useConventionalBnbPoolV2LiquidityPair<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "LIQUIDITY_PAIR"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "LIQUIDITY_PAIR",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "LIQUIDITY_PAIR",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "LIQUIDITY_PAIR", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"QUINT_TOKEN"`.
 */
export function useConventionalBnbPoolV2QuintToken<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "QUINT_TOKEN"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "QUINT_TOKEN",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "QUINT_TOKEN",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "QUINT_TOKEN", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"accountDetails"`.
 */
export function useConventionalBnbPoolV2AccountDetails<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "accountDetails"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "accountDetails",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "accountDetails",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "accountDetails", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"calculateLPReward"`.
 */
export function useConventionalBnbPoolV2CalculateLpReward<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "calculateLPReward"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "calculateLPReward",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "calculateLPReward",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "calculateLPReward", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"getTokenForLP"`.
 */
export function useConventionalBnbPoolV2GetTokenForLp<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "getTokenForLP"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "getTokenForLP",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "getTokenForLP",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "getTokenForLP", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"getTokenReserve"`.
 */
export function useConventionalBnbPoolV2GetTokenReserve<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "getTokenReserve"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "getTokenReserve",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "getTokenReserve",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "getTokenReserve", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"isBanned"`.
 */
export function useConventionalBnbPoolV2IsBanned<
  TSelectData = ReadContractResult<typeof conventionalBnbPoolV2ABI, "isBanned">
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "isBanned",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "isBanned",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "isBanned", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"isEmergencyWithdrawEnabled"`.
 */
export function useConventionalBnbPoolV2IsEmergencyWithdrawEnabled<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "isEmergencyWithdrawEnabled"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "isEmergencyWithdrawEnabled",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "isEmergencyWithdrawEnabled",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "isEmergencyWithdrawEnabled", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"isExemptFromTaxation"`.
 */
export function useConventionalBnbPoolV2IsExemptFromTaxation<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "isExemptFromTaxation"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "isExemptFromTaxation",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "isExemptFromTaxation",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "isExemptFromTaxation", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"isMigrated"`.
 */
export function useConventionalBnbPoolV2IsMigrated<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "isMigrated"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "isMigrated",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "isMigrated",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "isMigrated", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"isPaused"`.
 */
export function useConventionalBnbPoolV2IsPaused<
  TSelectData = ReadContractResult<typeof conventionalBnbPoolV2ABI, "isPaused">
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "isPaused",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "isPaused",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "isPaused", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"legacyStakingPool"`.
 */
export function useConventionalBnbPoolV2LegacyStakingPool<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "legacyStakingPool"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "legacyStakingPool",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "legacyStakingPool",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "legacyStakingPool", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"minimumStakingAmount"`.
 */
export function useConventionalBnbPoolV2MinimumStakingAmount<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "minimumStakingAmount"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "minimumStakingAmount",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "minimumStakingAmount",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "minimumStakingAmount", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"owner"`.
 */
export function useConventionalBnbPoolV2Owner<
  TSelectData = ReadContractResult<typeof conventionalBnbPoolV2ABI, "owner">
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "owner",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "owner",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "owner", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"rewardDivider"`.
 */
export function useConventionalBnbPoolV2RewardDivider<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "rewardDivider"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "rewardDivider",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "rewardDivider",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "rewardDivider", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"rewardMultiplier"`.
 */
export function useConventionalBnbPoolV2RewardMultiplier<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "rewardMultiplier"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "rewardMultiplier",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "rewardMultiplier",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "rewardMultiplier", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"rewardVault"`.
 */
export function useConventionalBnbPoolV2RewardVault<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "rewardVault"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "rewardVault",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "rewardVault",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "rewardVault", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"stakingDetails"`.
 */
export function useConventionalBnbPoolV2StakingDetails<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "stakingDetails"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "stakingDetails",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "stakingDetails",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "stakingDetails", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"totalHarvestedRewards"`.
 */
export function useConventionalBnbPoolV2TotalHarvestedRewards<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "totalHarvestedRewards"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "totalHarvestedRewards",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "totalHarvestedRewards",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "totalHarvestedRewards", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"totalStakedLPs"`.
 */
export function useConventionalBnbPoolV2TotalStakedLPs<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "totalStakedLPs"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "totalStakedLPs",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "totalStakedLPs",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "totalStakedLPs", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"totalUniqueStakers"`.
 */
export function useConventionalBnbPoolV2TotalUniqueStakers<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "totalUniqueStakers"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "totalUniqueStakers",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "totalUniqueStakers",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "totalUniqueStakers", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"totalUnstakedLPs"`.
 */
export function useConventionalBnbPoolV2TotalUnstakedLPs<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "totalUnstakedLPs"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "totalUnstakedLPs",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "totalUnstakedLPs",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "totalUnstakedLPs", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"unstakeTaxPercentage"`.
 */
export function useConventionalBnbPoolV2UnstakeTaxPercentage<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "unstakeTaxPercentage"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "unstakeTaxPercentage",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "unstakeTaxPercentage",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "unstakeTaxPercentage", TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"unstakeTaxRemovedAt"`.
 */
export function useConventionalBnbPoolV2UnstakeTaxRemovedAt<
  TSelectData = ReadContractResult<
    typeof conventionalBnbPoolV2ABI,
    "unstakeTaxRemovedAt"
  >
>(
  config: Omit<
    UseContractReadConfig<
      typeof conventionalBnbPoolV2ABI,
      "unstakeTaxRemovedAt",
      TSelectData
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return useContractRead({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "unstakeTaxRemovedAt",
    ...config,
  } as UseContractReadConfig<typeof conventionalBnbPoolV2ABI, "unstakeTaxRemovedAt", TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__.
 */
export function useConventionalBnbPoolV2Write<
  TMode extends WriteContractMode,
  TFunctionName extends string
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV2ABI,
          string
        >["abi"],
        TFunctionName
      >
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV2ABI,
        TFunctionName
      > & {
        abi?: never;
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV2ABI,
    TFunctionName
  >({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"SetExemptFromTaxation"`.
 */
export function useConventionalBnbPoolV2SetExemptFromTaxation<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV2ABI,
          "SetExemptFromTaxation"
        >["abi"],
        "SetExemptFromTaxation"
      > & { functionName?: "SetExemptFromTaxation" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV2ABI,
        "SetExemptFromTaxation"
      > & {
        abi?: never;
        functionName?: "SetExemptFromTaxation";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV2ABI,
    "SetExemptFromTaxation"
  >({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "SetExemptFromTaxation",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"SetMinimumStakingAmount"`.
 */
export function useConventionalBnbPoolV2SetMinimumStakingAmount<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV2ABI,
          "SetMinimumStakingAmount"
        >["abi"],
        "SetMinimumStakingAmount"
      > & { functionName?: "SetMinimumStakingAmount" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV2ABI,
        "SetMinimumStakingAmount"
      > & {
        abi?: never;
        functionName?: "SetMinimumStakingAmount";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV2ABI,
    "SetMinimumStakingAmount"
  >({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "SetMinimumStakingAmount",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"SetPoolAPYSettings"`.
 */
export function useConventionalBnbPoolV2SetPoolApySettings<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV2ABI,
          "SetPoolAPYSettings"
        >["abi"],
        "SetPoolAPYSettings"
      > & { functionName?: "SetPoolAPYSettings" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV2ABI,
        "SetPoolAPYSettings"
      > & {
        abi?: never;
        functionName?: "SetPoolAPYSettings";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV2ABI,
    "SetPoolAPYSettings"
  >({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "SetPoolAPYSettings",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"SetUnstakeTaxAndDuration"`.
 */
export function useConventionalBnbPoolV2SetUnstakeTaxAndDuration<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV2ABI,
          "SetUnstakeTaxAndDuration"
        >["abi"],
        "SetUnstakeTaxAndDuration"
      > & { functionName?: "SetUnstakeTaxAndDuration" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV2ABI,
        "SetUnstakeTaxAndDuration"
      > & {
        abi?: never;
        functionName?: "SetUnstakeTaxAndDuration";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV2ABI,
    "SetUnstakeTaxAndDuration"
  >({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "SetUnstakeTaxAndDuration",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"emergencyWithdraw"`.
 */
export function useConventionalBnbPoolV2EmergencyWithdraw<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV2ABI,
          "emergencyWithdraw"
        >["abi"],
        "emergencyWithdraw"
      > & { functionName?: "emergencyWithdraw" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV2ABI,
        "emergencyWithdraw"
      > & {
        abi?: never;
        functionName?: "emergencyWithdraw";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV2ABI,
    "emergencyWithdraw"
  >({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "emergencyWithdraw",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"harvest"`.
 */
export function useConventionalBnbPoolV2Harvest<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV2ABI,
          "harvest"
        >["abi"],
        "harvest"
      > & { functionName?: "harvest" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV2ABI,
        "harvest"
      > & {
        abi?: never;
        functionName?: "harvest";
      } = {} as any
) {
  return useContractWrite<TMode, typeof conventionalBnbPoolV2ABI, "harvest">({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "harvest",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"migrate"`.
 */
export function useConventionalBnbPoolV2Migrate<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV2ABI,
          "migrate"
        >["abi"],
        "migrate"
      > & { functionName?: "migrate" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV2ABI,
        "migrate"
      > & {
        abi?: never;
        functionName?: "migrate";
      } = {} as any
) {
  return useContractWrite<TMode, typeof conventionalBnbPoolV2ABI, "migrate">({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "migrate",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useConventionalBnbPoolV2RenounceOwnership<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV2ABI,
          "renounceOwnership"
        >["abi"],
        "renounceOwnership"
      > & { functionName?: "renounceOwnership" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV2ABI,
        "renounceOwnership"
      > & {
        abi?: never;
        functionName?: "renounceOwnership";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV2ABI,
    "renounceOwnership"
  >({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "renounceOwnership",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"setAllowEmergencyWithdraw"`.
 */
export function useConventionalBnbPoolV2SetAllowEmergencyWithdraw<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV2ABI,
          "setAllowEmergencyWithdraw"
        >["abi"],
        "setAllowEmergencyWithdraw"
      > & { functionName?: "setAllowEmergencyWithdraw" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV2ABI,
        "setAllowEmergencyWithdraw"
      > & {
        abi?: never;
        functionName?: "setAllowEmergencyWithdraw";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV2ABI,
    "setAllowEmergencyWithdraw"
  >({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "setAllowEmergencyWithdraw",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"setBanState"`.
 */
export function useConventionalBnbPoolV2SetBanState<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV2ABI,
          "setBanState"
        >["abi"],
        "setBanState"
      > & { functionName?: "setBanState" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV2ABI,
        "setBanState"
      > & {
        abi?: never;
        functionName?: "setBanState";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV2ABI,
    "setBanState"
  >({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "setBanState",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"setPoolPauseState"`.
 */
export function useConventionalBnbPoolV2SetPoolPauseState<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV2ABI,
          "setPoolPauseState"
        >["abi"],
        "setPoolPauseState"
      > & { functionName?: "setPoolPauseState" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV2ABI,
        "setPoolPauseState"
      > & {
        abi?: never;
        functionName?: "setPoolPauseState";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV2ABI,
    "setPoolPauseState"
  >({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "setPoolPauseState",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"setRewardVault"`.
 */
export function useConventionalBnbPoolV2SetRewardVault<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV2ABI,
          "setRewardVault"
        >["abi"],
        "setRewardVault"
      > & { functionName?: "setRewardVault" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV2ABI,
        "setRewardVault"
      > & {
        abi?: never;
        functionName?: "setRewardVault";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV2ABI,
    "setRewardVault"
  >({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "setRewardVault",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"stake"`.
 */
export function useConventionalBnbPoolV2Stake<TMode extends WriteContractMode>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV2ABI,
          "stake"
        >["abi"],
        "stake"
      > & { functionName?: "stake" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV2ABI,
        "stake"
      > & {
        abi?: never;
        functionName?: "stake";
      } = {} as any
) {
  return useContractWrite<TMode, typeof conventionalBnbPoolV2ABI, "stake">({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "stake",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useConventionalBnbPoolV2TransferOwnership<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV2ABI,
          "transferOwnership"
        >["abi"],
        "transferOwnership"
      > & { functionName?: "transferOwnership" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV2ABI,
        "transferOwnership"
      > & {
        abi?: never;
        functionName?: "transferOwnership";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV2ABI,
    "transferOwnership"
  >({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "transferOwnership",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"unstake"`.
 */
export function useConventionalBnbPoolV2Unstake<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV2ABI,
          "unstake"
        >["abi"],
        "unstake"
      > & { functionName?: "unstake" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV2ABI,
        "unstake"
      > & {
        abi?: never;
        functionName?: "unstake";
      } = {} as any
) {
  return useContractWrite<TMode, typeof conventionalBnbPoolV2ABI, "unstake">({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "unstake",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"unstakePartial"`.
 */
export function useConventionalBnbPoolV2UnstakePartial<
  TMode extends WriteContractMode
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<
          typeof conventionalBnbPoolV2ABI,
          "unstakePartial"
        >["abi"],
        "unstakePartial"
      > & { functionName?: "unstakePartial" }
    : UseContractWriteConfig<
        TMode,
        typeof conventionalBnbPoolV2ABI,
        "unstakePartial"
      > & {
        abi?: never;
        functionName?: "unstakePartial";
      } = {} as any
) {
  return useContractWrite<
    TMode,
    typeof conventionalBnbPoolV2ABI,
    "unstakePartial"
  >({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "unstakePartial",
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__.
 */
export function usePrepareConventionalBnbPoolV2Write<
  TFunctionName extends string
>(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV2ABI,
      TFunctionName
    >,
    "abi" | "address"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"SetExemptFromTaxation"`.
 */
export function usePrepareConventionalBnbPoolV2SetExemptFromTaxation(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV2ABI,
      "SetExemptFromTaxation"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "SetExemptFromTaxation",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "SetExemptFromTaxation">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"SetMinimumStakingAmount"`.
 */
export function usePrepareConventionalBnbPoolV2SetMinimumStakingAmount(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV2ABI,
      "SetMinimumStakingAmount"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "SetMinimumStakingAmount",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "SetMinimumStakingAmount">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"SetPoolAPYSettings"`.
 */
export function usePrepareConventionalBnbPoolV2SetPoolApySettings(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV2ABI,
      "SetPoolAPYSettings"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "SetPoolAPYSettings",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "SetPoolAPYSettings">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"SetUnstakeTaxAndDuration"`.
 */
export function usePrepareConventionalBnbPoolV2SetUnstakeTaxAndDuration(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV2ABI,
      "SetUnstakeTaxAndDuration"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "SetUnstakeTaxAndDuration",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "SetUnstakeTaxAndDuration">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"emergencyWithdraw"`.
 */
export function usePrepareConventionalBnbPoolV2EmergencyWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV2ABI,
      "emergencyWithdraw"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "emergencyWithdraw",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "emergencyWithdraw">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"harvest"`.
 */
export function usePrepareConventionalBnbPoolV2Harvest(
  config: Omit<
    UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "harvest">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "harvest",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "harvest">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"migrate"`.
 */
export function usePrepareConventionalBnbPoolV2Migrate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "migrate">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "migrate",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "migrate">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareConventionalBnbPoolV2RenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV2ABI,
      "renounceOwnership"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "renounceOwnership",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "renounceOwnership">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"setAllowEmergencyWithdraw"`.
 */
export function usePrepareConventionalBnbPoolV2SetAllowEmergencyWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV2ABI,
      "setAllowEmergencyWithdraw"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "setAllowEmergencyWithdraw",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "setAllowEmergencyWithdraw">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"setBanState"`.
 */
export function usePrepareConventionalBnbPoolV2SetBanState(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV2ABI,
      "setBanState"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "setBanState",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "setBanState">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"setPoolPauseState"`.
 */
export function usePrepareConventionalBnbPoolV2SetPoolPauseState(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV2ABI,
      "setPoolPauseState"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "setPoolPauseState",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "setPoolPauseState">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"setRewardVault"`.
 */
export function usePrepareConventionalBnbPoolV2SetRewardVault(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV2ABI,
      "setRewardVault"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "setRewardVault",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "setRewardVault">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"stake"`.
 */
export function usePrepareConventionalBnbPoolV2Stake(
  config: Omit<
    UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "stake">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "stake",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "stake">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareConventionalBnbPoolV2TransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV2ABI,
      "transferOwnership"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "transferOwnership",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "transferOwnership">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"unstake"`.
 */
export function usePrepareConventionalBnbPoolV2Unstake(
  config: Omit<
    UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "unstake">,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "unstake",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "unstake">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `functionName` set to `"unstakePartial"`.
 */
export function usePrepareConventionalBnbPoolV2UnstakePartial(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof conventionalBnbPoolV2ABI,
      "unstakePartial"
    >,
    "abi" | "address" | "functionName"
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    functionName: "unstakePartial",
    ...config,
  } as UsePrepareContractWriteConfig<typeof conventionalBnbPoolV2ABI, "unstakePartial">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__.
 */
export function useConventionalBnbPoolV2Event<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof conventionalBnbPoolV2ABI, TEventName>,
    "abi" | "address"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    ...config,
  } as UseContractEventConfig<typeof conventionalBnbPoolV2ABI, TEventName>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `eventName` set to `"Banned"`.
 */
export function useConventionalBnbPoolV2BannedEvent(
  config: Omit<
    UseContractEventConfig<typeof conventionalBnbPoolV2ABI, "Banned">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    eventName: "Banned",
    ...config,
  } as UseContractEventConfig<typeof conventionalBnbPoolV2ABI, "Banned">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `eventName` set to `"EmergencyWithdrawalEnabled"`.
 */
export function useConventionalBnbPoolV2EmergencyWithdrawalEnabledEvent(
  config: Omit<
    UseContractEventConfig<
      typeof conventionalBnbPoolV2ABI,
      "EmergencyWithdrawalEnabled"
    >,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    eventName: "EmergencyWithdrawalEnabled",
    ...config,
  } as UseContractEventConfig<typeof conventionalBnbPoolV2ABI, "EmergencyWithdrawalEnabled">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `eventName` set to `"EmergencyWithdrawn"`.
 */
export function useConventionalBnbPoolV2EmergencyWithdrawnEvent(
  config: Omit<
    UseContractEventConfig<
      typeof conventionalBnbPoolV2ABI,
      "EmergencyWithdrawn"
    >,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    eventName: "EmergencyWithdrawn",
    ...config,
  } as UseContractEventConfig<typeof conventionalBnbPoolV2ABI, "EmergencyWithdrawn">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `eventName` set to `"Exempted"`.
 */
export function useConventionalBnbPoolV2ExemptedEvent(
  config: Omit<
    UseContractEventConfig<typeof conventionalBnbPoolV2ABI, "Exempted">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    eventName: "Exempted",
    ...config,
  } as UseContractEventConfig<typeof conventionalBnbPoolV2ABI, "Exempted">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `eventName` set to `"Harvested"`.
 */
export function useConventionalBnbPoolV2HarvestedEvent(
  config: Omit<
    UseContractEventConfig<typeof conventionalBnbPoolV2ABI, "Harvested">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    eventName: "Harvested",
    ...config,
  } as UseContractEventConfig<typeof conventionalBnbPoolV2ABI, "Harvested">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `eventName` set to `"Migrated"`.
 */
export function useConventionalBnbPoolV2MigratedEvent(
  config: Omit<
    UseContractEventConfig<typeof conventionalBnbPoolV2ABI, "Migrated">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    eventName: "Migrated",
    ...config,
  } as UseContractEventConfig<typeof conventionalBnbPoolV2ABI, "Migrated">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `eventName` set to `"OwnershipTransferred"`.
 */
export function useConventionalBnbPoolV2OwnershipTransferredEvent(
  config: Omit<
    UseContractEventConfig<
      typeof conventionalBnbPoolV2ABI,
      "OwnershipTransferred"
    >,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    eventName: "OwnershipTransferred",
    ...config,
  } as UseContractEventConfig<typeof conventionalBnbPoolV2ABI, "OwnershipTransferred">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `eventName` set to `"Paused"`.
 */
export function useConventionalBnbPoolV2PausedEvent(
  config: Omit<
    UseContractEventConfig<typeof conventionalBnbPoolV2ABI, "Paused">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    eventName: "Paused",
    ...config,
  } as UseContractEventConfig<typeof conventionalBnbPoolV2ABI, "Paused">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `eventName` set to `"Staked"`.
 */
export function useConventionalBnbPoolV2StakedEvent(
  config: Omit<
    UseContractEventConfig<typeof conventionalBnbPoolV2ABI, "Staked">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    eventName: "Staked",
    ...config,
  } as UseContractEventConfig<typeof conventionalBnbPoolV2ABI, "Staked">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link conventionalBnbPoolV2ABI}__ and `eventName` set to `"Unstaked"`.
 */
export function useConventionalBnbPoolV2UnstakedEvent(
  config: Omit<
    UseContractEventConfig<typeof conventionalBnbPoolV2ABI, "Unstaked">,
    "abi" | "address" | "eventName"
  > = {} as any
) {
  return useContractEvent({
    abi: conventionalBnbPoolV2ABI,
    address: conventionalBnbPoolV2Address,
    eventName: "Unstaked",
    ...config,
  } as UseContractEventConfig<typeof conventionalBnbPoolV2ABI, "Unstaked">);
}
