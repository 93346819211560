import { cn } from "@quint/ui";
import { NextSeo } from "next-seo";
import Head from "next/head";
import type { PropsWithChildren } from "react";
import Footer from "./footer";
import Header from "./header";

import { useCanGoBack, useHeaderPadding } from "@quint/utils/hooks";
import BackButton from "./back";
type Props = {
  title?: string;
  description?: string;
  url?: string;
  className?: string;
  includeHeaderPadding?: boolean;
};

const site = "https://dev-new.quint.io";
// const image = new URL("social.jpg", site);

const Layout = (props: PropsWithChildren<Props>) => {
  const canGoBack = useCanGoBack();
  const headerPadding = useHeaderPadding();
  const {
    children,
    title = "QUINT STAKING",
    description = "QUINT Staking | Passive Income, Luxury Rewards, and More",
    className,
    includeHeaderPadding = true,
  } = props;
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <NextSeo
        title={title}
        description={description}
        canonical={props.url}
        openGraph={{
          url: props.url,
          title: title,
          description: description,
          images: [
            {
              url: site + "/social.jpg",
              width: 1200,
              height: 630,
              alt: props.title,
            },
          ],
          site_name: title,
        }}
        twitter={{
          handle: "@projectquint",
          site: "@projectquint",
          cardType: "summary_large_image",
        }}
      />

      <main
        className={cn(
          "relative flex min-h-screen grow flex-col antialiased",
          includeHeaderPadding ? headerPadding : "",
          className
        )}
      >
        <div className="absolute inset-x-0 top-0 z-50">
          <Header />
          {canGoBack && <BackButton />}
        </div>
        <div className="site-hero-gradient-background-light dark:site-hero-gradient-background-dark absolute inset-0 z-[-10] flex w-full">
          <div className="hero-light-gradient dark:hero-dark-gradient max-h-[969px]"></div>
          <div className="site-bg-gradient-light dark:site-bg-gradient-dark flex flex-1"></div>
        </div>

        <div className="relative flex grow flex-col">{children}</div>
        <Footer />
      </main>
    </>
  );
};

export default Layout;
