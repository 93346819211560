import { useAccount, useBalance, useQuery } from "wagmi";

import { conventionalBnbPoolV1Address } from "./conventional-bnb-pool-v1";
import { conventionalBnbPoolV2Address } from "./conventional-bnb-pool-v2";
import { conventionalPoolV1Address } from "./conventional-pool-v1";
import { conventionalPoolV2Address } from "./conventional-pool-v2";
import { quintAddress, useQuintBalanceOf } from "./quint";
import { BigNumber } from "ethers";
import { useMemo } from "react";
import { luxuryPoolAAddress } from "./luxury-pool-a";
import { luxuryPoolBAddress } from "./luxury-pool-b";
import { useLpBalanceOf } from "./lp";
import { fromBn, toBn } from "evm-bn";

interface QuintRateResponse {
  rate: number;
}
export const useQuintRate = () => {
  const query = useQuery<QuintRateResponse>(["QUINT_RATE"], async () => {
    return (await fetch("https://rate.quint.io/Quint_vs_USD_API"))?.json();
  });
  const data = query?.data?.rate || 0;
  return useMemo(
    () => ({
      data,
      isLoading: query.isLoading,
    }),
    [data, query.isLoading]
  );
};
export const useQuintBalance = () => {
  const { address } = useAccount();

  return useBalance({
    address,
    token: quintAddress,
    enabled: Boolean(address),
    watch: true,
  });
};

export const useTotalConventionalStakedQuints = () => {
  const conventionalV1Query = useQuintBalanceOf({
    args: [conventionalPoolV1Address],
    watch: true,
  });

  const conventionalV2Query = useQuintBalanceOf({
    args: [conventionalPoolV2Address],
    watch: true,
  });

  const conventionalBNBV1Query = useQuintBalanceOf({
    args: [conventionalBnbPoolV1Address],
    watch: true,
  });
  const conventionalBNBV2Query = useQuintBalanceOf({
    args: [conventionalBnbPoolV2Address],
    watch: true,
  });
  const conventionalLPV1Query = useLpBalanceOf({
    args: [conventionalPoolV1Address],
    watch: true,
  });

  const conventionalLPV2Query = useLpBalanceOf({
    args: [conventionalPoolV2Address],
    watch: true,
  });

  const conventionalLPBNBV1Query = useLpBalanceOf({
    args: [conventionalBnbPoolV1Address],
    watch: true,
  });
  const conventionalLPBNBV2Query = useLpBalanceOf({
    args: [conventionalBnbPoolV2Address],
    watch: true,
  });
  const data = BigNumber.from(conventionalV1Query.data || "0")
    .add(BigNumber.from(conventionalV2Query.data || "0"))
    .add(BigNumber.from(conventionalBNBV1Query.data || "0"))
    .add(BigNumber.from(conventionalBNBV2Query.data || "0"))
    .add(BigNumber.from(conventionalLPV1Query.data || "0"))
    .add(BigNumber.from(conventionalLPV2Query.data || "0"))
    .add(BigNumber.from(conventionalLPBNBV1Query.data || "0"))
    .add(BigNumber.from(conventionalLPBNBV2Query.data || "0"));

  const isLoading =
    conventionalV1Query.isLoading ||
    conventionalV2Query.isLoading ||
    conventionalBNBV1Query.isLoading ||
    conventionalBNBV2Query.isLoading ||
    conventionalLPV1Query.isLoading ||
    conventionalLPV2Query.isLoading ||
    conventionalLPBNBV1Query.isLoading ||
    conventionalLPBNBV2Query.isLoading;
  return useMemo(() => ({ data, isLoading }), [data, isLoading]);
};

export const useTotalConventionalStakedQuintsTVL = () => {
  const quintRateQuery = useQuintRate();
  const conventionalQuery = useTotalConventionalStakedQuints();
  const data = toBn(`${+fromBn(conventionalQuery.data, 18) * quintRateQuery.data}`);
  const isLoading = quintRateQuery.isLoading || conventionalQuery.isLoading;
  return useMemo(() => ({ data, isLoading }), [data, isLoading]);
};
export const useTotalLuxuryStakedQuints = () => {
  const luxuryPoolAQuery = useQuintBalanceOf({
    args: [luxuryPoolAAddress],
    watch: true,
  });

  const luxuryPoolBQuery = useQuintBalanceOf({
    args: [luxuryPoolBAddress],
    watch: true,
  });
  const LPluxuryPoolAQuery = useLpBalanceOf({
    args: [luxuryPoolAAddress],
    watch: true,
  });

  const LPluxuryPoolBQuery = useLpBalanceOf({
    args: [luxuryPoolBAddress],
    watch: true,
  });

  const data = BigNumber.from(luxuryPoolAQuery.data || "0")
    .add(BigNumber.from(luxuryPoolBQuery.data || "0"))
    .add(BigNumber.from(LPluxuryPoolAQuery.data || "0"))
    .add(BigNumber.from(LPluxuryPoolBQuery.data || "0"));

  const isLoading =
    luxuryPoolAQuery.isLoading ||
    luxuryPoolBQuery.isLoading ||
    LPluxuryPoolAQuery.isLoading ||
    LPluxuryPoolBQuery.isLoading;
  return useMemo(() => ({ data, isLoading }), [data, isLoading]);
};

export const useTotalStakedQuints = () => {
  const conventionalQuery = useTotalConventionalStakedQuints();
  const luxuryQuery = useTotalLuxuryStakedQuints();
  const data = BigNumber.from(conventionalQuery.data || "0").add(BigNumber.from(luxuryQuery.data || "0"));
  const isLoading = conventionalQuery.isLoading || luxuryQuery.isLoading;
  return useMemo(() => ({ data, isLoading }), [data, isLoading]);
};

export const useTotalStakedQuintsTVL = () => {
  const quintRateQuery = useQuintRate();

  const totalStakedQuery = useTotalStakedQuints();
  const data = toBn(`${+fromBn(totalStakedQuery.data, 18) * quintRateQuery.data}`);

  const isLoading = quintRateQuery.isLoading || totalStakedQuery.isLoading;
  return useMemo(() => ({ data, isLoading }), [data, isLoading]);
};
