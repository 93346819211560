import CloseIcon from "@quint/ui/icons/close";
import MenuIcon from "@quint/ui/icons/menu";
import Link from "next/link";
import { useReducer } from "react";
import MainLogo from "../main-logo";
import ThemeToggler from "../theme-toggler";
import ConnectWallet from "./connect-wallet";
import { navItems } from "./nav";

// TODO: Scroll through mobile modal
const MobileMenu = () => {
  const [isOpen, toggleOpen] = useReducer((state) => !state, false);
  return (
    <>
      <button id="open-nav-button" type="button" className="btn" aria-label="Navigation" onClick={toggleOpen}>
        <MenuIcon className="text-default" height={24} width={24} />
      </button>
      {isOpen && (
        <div
          role="dialog"
          aria-modal="true"
          className="x-padding fixed inset-0 z-50 flex h-full flex-col gap-7 space-y-4 bg-white py-[24px] text-default dark:bg-[#0B2352] md:py-[36px]"
        >
          <header className="flex items-center justify-between">
            <MainLogo />
            <button id="close-nav-button" type="button" aria-label="Close navigation" onClick={toggleOpen}>
              <CloseIcon className="text-default" height={24} width={24} />
            </button>
          </header>

          <nav>
            <ul className="flex flex-col">
              {navItems.map(({ title, url }) => (
                <li key={title}>
                  <Link
                    className="block py-4 text-center font-poppins font-medium text-default text-xl"
                    href={url}
                    target="_blank"
                  >
                    {title}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <div className="h-[1px] w-full bg-gray-400/40 dark:bg-white"></div>

          <div className="x-padding absolute bottom-0 left-0 right-0 flex items-center justify-between gap-10 py-[24px]">
            <ConnectWallet />
            <ThemeToggler />
          </div>
        </div>
      )}
    </>
  );
};

export default MobileMenu;
