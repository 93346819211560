import { Image } from "@quint/ui";
import FooterBG from "public/images/footer.png";

("use client");
const BackgroundImage = () => {
  return (
    <div className="absolute z-[-1] h-full w-[100vw] overflow-hidden">
      <Image
        src={FooterBG}
        fill
        priority
        placeholder="blur"
        className="object-cover"
        alt="Footer background"
        quality={100}
        loading="eager"
        sizes="100vw"
      />
      <div className="footer-light-gradient dark:footer-dark-gradient absolute inset-0" />
    </div>
  );
};

export default BackgroundImage;
