import { createElement, forwardRef, type HTMLAttributes } from "react";
import type { VariantProps } from "./utils";
import { cn, createVariants } from "./utils";

const typographyVariants = createVariants("font-inter tracking-[0%]", {
  variants: {
    variant: {
      h1: "font-satoshi font-bold text-[48px] leading-[55px] md:text-[96px] md:leading-[108px]",
      h2: "font-satoshi font-bold text-[36px] leading-[48px] md:text-[64px] md:leading-[72px]",
      h3: "font-satoshi font-bold text-[30px] leading-[38px] md:text-[56px] md:leading-[64px]",
      h4: "font-satoshi font-bold text-[24px] leading-[32px] md:text-[40px] md:leading-[48px]",
      h5: "font-satoshi font-bold text-[30px] leading-[38px]",
      h6: "font-satoshi font-bold text-[24px] leading-[32px]",

      xlSemiBold: "font-semibold text-[22px] leading-[30px]",
      lgSemiBold: "font-semibold text-[18px] leading-[30px]",
      xsBold: "font-bold text-[12px] leading-[16px]",

      lgMedium: "font-medium text-[18px] leading-[30px]",
      baseMedium: "font-medium text-[16px] leading-[26px]",
      smMedium: "font-medium text-[14px] leading-[24px]",
      xsMedium: "font-medium text-[12px] leading-[16px]",

      xlRegular: "font-normal text-[22px] leading-[30px]",
      lgRegular: "font-normal text-[18px] leading-[30px]",
      baseRegular: "font-normal text-[16px] leading-[26px]",
      smRegular: "font-normal text-[14px] leading-[24px]",
    },

    defaultVariants: {
      variant: "baseRegular",
    },
  },
});

export type TypographyProps = HTMLAttributes<HTMLParagraphElement> &
  VariantProps<typeof typographyVariants> & {
    as?: keyof JSX.IntrinsicElements;
  };

const Typography = forwardRef<HTMLParagraphElement, TypographyProps>(({ className, variant, as, ...props }, ref) => {
  return createElement(
    as ?? (variant?.startsWith("h") ? variant : "p"),
    {
      className: cn(typographyVariants({ variant, className })),
      ref,
      ...props,
    },
    props.children
  );
});
Typography.displayName = "Typography";

export { Typography, typographyVariants };
