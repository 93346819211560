import clsx from "clsx";
import Link from "next/link";

export const navItems = [
  { title: "Stake Now", url: "https://stake.quint.io/", active: true },
  { title: "Quint Swap", url: "https://swap.quint.io/" },
  { title: "NFT Marketplace", url: "https://nft.quint.io/marketplace" },
  { title: "Blog", url: "https://blog.quint.io/" },
];

const Nav = () => {
  return (
    <nav>
      <ul className="flex items-center gap-10">
        {navItems.map(({ title, url, active }) => (
          <li key={title}>
            <Link
              className={clsx([
                "font-poppins font-medium text-default text-sm",
                {
                  "text-primary": active,
                },
              ])}
              href={url}
              target="_blank"
            >
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Nav;
