import * as React from "react";

function QuintText(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={78}
      height={20}
      viewBox="0 0 78 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.328 9.673c0 2.283-.673 4.26-2.092 5.935l1.793 1.902-2.39 2.206-1.868-1.978A8.587 8.587 0 019.44 18.88c-2.465 0-4.557-.913-6.35-2.663C1.371 14.466.475 12.26.475 9.748c0-2.51.896-4.717 2.614-6.467S6.974.619 9.44.619s4.63.913 6.349 2.663c1.643 1.598 2.54 3.804 2.54 6.391zM9.364 15.38c.672 0 1.27-.077 1.942-.305l-2.838-3.043 2.39-2.207 2.988 3.196a5.972 5.972 0 001.046-3.424c0-1.674-.523-3.044-1.57-4.109-1.045-1.065-2.39-1.598-3.958-1.598-1.569 0-2.913.533-3.96 1.598C4.36 6.553 3.837 7.923 3.837 9.597s.523 3.043 1.569 4.109c1.046 1.14 2.39 1.674 3.959 1.674zM28.71 18.803c-1.941 0-3.51-.532-4.705-1.674-1.195-1.141-1.867-2.587-1.867-4.413V.846h3.36v11.566c0 .913.225 1.674.748 2.206.523.533 1.344.837 2.39.837s1.942-.304 2.465-.837c.523-.532.747-1.293.747-2.206V.847h3.436V12.64c0 1.826-.597 3.348-1.867 4.413-1.27 1.065-2.764 1.75-4.706 1.75zM39.916.847h3.436v17.576h-3.436V.847zM57.918.847h3.436v17.576H58.74L51.345 7.695v10.728h-3.437V.847h2.615l7.395 10.728V.847zM77.415.847v3.348h-4.632v14.228h-3.436V4.195h-4.631V.847h12.699z"
        fill="currentColor"
      />
    </svg>
  );
}

export default QuintText;
